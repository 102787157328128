.removeSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.optionsPopup {
  max-width: 25rem;
  :global(.ant-select-item-option-content) {
    overflow: visible;
    white-space: normal;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";