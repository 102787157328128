.loadingDuration {
  &Alert {
    margin-bottom: 1rem;
  }
  &Message  {
    color: var(--ant-color-text-base);
    font-size: var(--ant-font-size-sm);
  }
}

.durationAlert {
  font-style: italic;
  font-size: var(--ant-font-size-sm);
}

.trialAlert {
  margin: 1rem 0;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";