.userlex-page {
  .ant-card {
    margin-bottom: 0;

    &-body {
      margin-bottom: 0;
      padding-top: 0;
    }
  }

  .ant-table-placeholder {
    .ant-table-cell {
      border-bottom: none;
    }
  }

  .userlex-table {
    @media @display-md {
      width: 100%;
    }

    @media @display-lg {
      width: 100%;
    }

    margin-top: 0.5rem;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .userlex-icon>svg {
    width: 1rem;
    height: 1rem;
    margin-right: 10px;
  }

  .choose-lang {
    width: 200px;
  }

  .toolbar {
    margin-top: 1rem;
  }

  .form__item--hidden {
    display: none;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";