.appCard {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  @media @display-md {
    flex: 0 0 calc(50% - 1rem);
    margin: 1rem 0.5rem;
  }

  @media @display-lg {
    flex: 0 0 calc(33% - 1rem);
  }

  @media @display-xxl {
    flex: 0 0 calc(25% - 1rem);
  }
}

.icon {
  display: block;
  flex: 0 0 3rem;
  width: 3rem;
  height: 3rem;

  rect {
    fill: var(--ant-color-primary);
  }
}

.cardName {
  font-weight: var(--ant-font-weight-strong);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";