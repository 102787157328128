.signUp {
  &Header {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    margin-top: 1.5rem;
  }
  
  &Steps {
    margin-bottom: 1rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";