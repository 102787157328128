.contextMenuLabel {
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  width: 100%;
}

.shortcut {
  color: var(--ant-color-text-disabled);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";