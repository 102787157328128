.installedModels {
  margin-bottom: 0.5rem; 
}

.saveButton {
  display: flex;
  justify-content: end;
}

.modelName {
  font-weight: 500;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";