.helpContent {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}

.shortcut {
  display: block;
  text-align: center;
  p {
    margin-bottom: 0.5rem;
    font-size: initial;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";