.search:global(.ant-form-item) {
  margin-bottom: 0;
}

.searchInput :global(.ant-btn) {
  height: 32px;
  margin-right: 0;
  border: 3px solid var(--ant-layout-header-bg);

  span {
    color: white;
  }
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.fullWidthToolbar {
  container-type: inline-size;
}

@container (min-width: 700px) {
  :global(.ant-col.ant-form-item-control) {
    display: flex;
    flex-direction: row-reverse;
  }

  :global(.ant-form-item-explain) {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";