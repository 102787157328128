.dashpage {
  height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr;

  &__content {
    overflow-y: auto;
  }
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &--space-responsive {
    justify-content: space-evenly;
    @media @display-sm {
      justify-content: flex-start;
    }
  }

  &__left {
    margin: 0;
    display: flex;
    align-items: center;
  }

  &__right {
    margin: 0;
    span {
      transition: 0.3s;
    }
  }

  &__right--projs {
      padding-right: 24px;
    }
  
  &__right--lex {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--ant-color-text-base);
    .ant-select-selector {
      height: 26px;
    }
  }

  &__right .arrow-down {
    transform: rotate(-180deg);
  }

  &__item {
    margin: 0;
  
    &::first {
      margin-left: 0; 
    }
  }
}

.input-label {
  margin-right: 0.5rem;
}

.toolbar-btn {
  font-size: 120%;
  color: var(--ant-color-text-base);
  padding: 1px 2px;
  @media only screen and (min-width: 360px) {
    padding: 1px 3px;
  }
  @media @display-sm {
    padding: 1px 6px;
  }
  &:hover {
    color: var(--ant-color-primary-hover);
  }
  button:disabled {
    i, svg {
      color: var(--ant-color-border);
    }
  }
  &.ant-tooltip-disabled-compatible-wrapper {
    padding: 0;
  }
}

.flex-center-horizontal {
  display: flex;
  justify-content: center;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";