.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
}

.sortIcons {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  svg {
    color: var(--ant-color-text-secondary);
    height: 0.8rem;
    width: 0.9rem;
    &:hover {
      color: var(--ant-color-text-disabled);
    }
  }
  .active svg {
    color: var(--ant-color-primary)
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";