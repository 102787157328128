.layout {
  @media @display-md {
    width: 80%;
  }
  @media @display-xl {
    width: 60%;
  }
  @media @display-xxl {
    width: 50%;
  }
  width: 100%;
}

.descriptions {
  margin: 2rem;
  :global(.ant-descriptions-header) {
    flex-wrap: wrap;
  }
  :global(.ant-descriptions-extra) {
    margin-inline-start: 0;
    @media @display-md {
      margin-inline-start: auto;
    }
  }
  :global(.ant-descriptions-title) {
    margin-bottom: 0.7rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";