.groupsTable {
  th {
    font-weight: var(--ant-font-weight-strong);
  }
  td, th {
    padding: 8px;
  }
  a, td, th {
    font-size: var(--font-size-tiny);
    word-break: break-all;
    @media @display-md {
      font-size: var(--ant-font-size);
    }
  }
}

.groupsCard {
  width: 100%;
  margin: 0 auto;
  @media @display-md {
    width: 70%;
  }
  @media @display-lg {
    width: 60%;
    margin: 0;
  }
  @media @display-xxl {
    width: 50%;
    margin: 0;
  }
}

.groupTitle {
  font-size: 1.1rem;
  font-weight: 500;
}

.addGroupButton {
  margin-bottom: 1rem;
}

.divider:global(.ant-divider) {
  margin-top: 0.3rem;
}

.hidden {
  display: none;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";