@keyframes fading {
  0% { background-image: url('img/wait-anim01.svg') }
  16.66% { background-image: url('img/wait-anim02.svg') }
  33.33% { background-image: url('img/wait-anim03.svg') }
  50% { background-image: url('img/wait-anim04.svg') }
  66.66% { background-image: url('img/wait-anim03.svg') }
  83.33% { background-image: url('img/wait-anim02.svg') }
  100% { background-image: url('img/wait-anim01.svg') }
}

.waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  
  &__anim {
    background-size: cover;
    animation-name: fading;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  
  &__message {
    font-weight: var(--ant-font-weight-strong);
    text-align: center;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";