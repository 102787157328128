.title {
  font-size: var(--font-size-large);
  font-weight: 500;
  margin: 2rem 1rem 0 1rem;   
}

.cards {
  display: grid;
  @media @display-sm {
    grid-template-columns: 50% 50%;
  }
  @media @display-xl {
    grid-template-columns: 25% 25% 25% 25%;
  }
  grid-template-columns: 1fr;
  gap: 0.7rem;
}

.plans {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media @display-sm {
    width: fit-content;
    display: block;
  }
}

.switchWrapper {
  text-align: center;
  margin-bottom: 1rem;
}

.switch {
  :global(.ant-radio-button-wrapper:first-child) {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
  }
  :global(.ant-radio-button-wrapper:last-child) {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.info {
  font-size: var(--font-size-tiny);
  margin-left: 1rem;
  display: block;
  position: relative;
  width: 100%;
  bottom: 0;
  @media @display-xl {
    display: inline;
    position: absolute;
    bottom: 1rem;
    width: fit-content;
  }
}

.sparkleIcon {
  vertical-align: super;
}

.arrowIcon {
  vertical-align: -0.3rem;
  margin-right: 0.5rem;
  color: var(--ant-color-info);
  font-size: var(--font-size-large);
  display: none;
  @media @display-xl {
    display: inline;
  }
}


@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";