.media-controls {
  background-color: var(--project-menu-bg);
  font-size: var(--font-size-tiny);
  margin: 0 auto;
  padding: 5px 1rem;
  @media @display-sm {
    height: 4rem;
    font-size: var(--ant-font-size);
  }

  @media @display-lg {
    margin-left: 1rem;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    order: -1;
    flex: 1;

    @media @display-sm {
      width: auto;
      justify-content: space-between;
      order: initial;
      padding-top: 5px;
      align-items: center;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: white;

      @media @display-sm {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 5px;

    &--caret-play {
      width: 2.3rem;
      height: 2.3rem;
      background-color: var(--ant-color-info);
      ;

      &:hover {
        background-color: var(--ant-color-info-hover);
      }

      @media @display-sm {
        width: 2.3rem;
        height: 2.3rem;
      }
    }

    &--play-pause {
      width: 2.5rem;
      height: 2.5rem;
      background-color: var(--ant-color-primary);

      &:hover {
        background-color: var(--ant-color-primary-hover);
      }

      @media @display-sm {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  &__settings {
    cursor: pointer;
    &:hover {
      color: var(--ant-color-primary-hover);
    }
  }

  &__layout {
    display: flex;
    align-items: center;
  }

  .cursor-play-icon svg {
    width: 1.1rem;
    height: 1rem;
    margin-bottom: 3px;

    @media @display-sm {
      width: 1.3rem;
      height: 1.2rem;
    }
  }

  .pause-icon svg,
  .play-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--disabled {
    .media-controls__btn {
      background: var(--ant-color-bg-container-disabled);
    }

    .playback-speed .speed-slider .ant-slider-handle {
      background-color: var(--ant-color-bg-container-disabled);

      &:hover {
        background-color: var(--ant-color-bg-container-disabled);
      }
    }
  }

  &--loading {
    .media-controls__btn {
      background: var(--ant-color-border);
    }

    .playback-speed .speed-slider .ant-slider-handle {
      background-color: var(--ant-color-border);

      &:hover {
        background-color: var(--ant-color-border);
      }
    }
  }

  &__time {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 0;
    justify-content: center;
    position: relative;
    font-size: var(--font-size-tiny);
    z-index: 0;
    width: 100%;

    @media @display-sm {
      flex-wrap: initial;
      padding: 0 14px 0 12px;
      justify-content: space-between;
      position: absolute;
    }
  }

  .timer-total {
    letter-spacing: 0.5px;
    margin: 0 5px;
  }
}

.seekbar {
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 14px;
  height: 1.5rem;
  position: relative;
  background-color: var(--project-menu-bg);
  display: flex;
  align-items: center;

  &__time {
    display: none;
    position: absolute;
    top: -18px;
    background-color: var(--ant-color-fill-tertiary);
    opacity: 0.9;
    border-radius: 5px;
    font-size: var(--font-size-tiny);
    padding: 2px 5px;
  }

  &__bg {
    cursor: pointer;
    width: calc(100% - 6px);
    height: 0.6rem;
    background-color: var(--ant-color-border);
    border-radius: 45px;
  }

  &__bar {
    background: var(--ant-color-primary);
    background: linear-gradient(90deg, var(--ant-color-primary-border-hover) 0%, var(--ant-color-primary-hover) 100%);
    height: 0.6rem;
    width: 0px;
    border-radius: 45px;
  }

  &--loading &__bg {
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: var(--ant-color-bg-container-disabled);
    background: linear-gradient(90deg, rgba(206, 206, 206, 1) 16%, #ffae4392 47%, rgba(206, 206, 206, 1) 81%);
    background-size: 100vw 300px;
    cursor: not-allowed;
  }

  &--disabled &__bg {
    background: var(--ant-color-bg-container-disabled);
    cursor: not-allowed;
  }

  &--disabled,
  &--loading {
    cursor: not-allowed;
  }

  &__knob {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 19px;
    height: 19px;
    background-color: var(--ant-color-primary);
    border-radius: 50%;
    margin-top: 2px;

    &:hover,
    &:focus {
      background-color: var(--ant-color-primary-hover);
    }
  }

  &--disabled &__knob,
  &--loading &__knob {
    background: var(--ant-color-bg-container-disabled);
    cursor: not-allowed;
  }
}

.seektime {
  width: 100%;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0 0
  }

  100% {
    background-position: 100vw 0
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";