.bigAlert {
  display: flex;
  padding: 1rem;
  gap: 1rem;
}

.body {
  display: flex;
  flex-direction: column;

  @media @display-xxl {
    flex-direction: row;
  }

  :global(.ant-btn) {
    align-self: flex-end;
    max-width: max-content;

    @media @display-xxl {
      align-self: center;
      margin-left: 1rem;
    }
  }
}

.text {
  flex: 1;
}

.header {
  font-weight: var(--ant-font-weight-strong);
  font-size: 1.3rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";