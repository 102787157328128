.keyboardShortcut {
  margin: 3px;
}

.iconButton {
  font-size: 120%;
  color: var(--ant-color-text-base);
  svg {
    width: 1em;
    height: 1em;
    fill: var(--ant-color-text-base);
  }
}

.iconButton:not([disabled]):hover, .iconButton:not([disabled]):hover svg {
  color: var(--ant-color-primary-hover);
  fill: var(--ant-color-primary-hover);
  :global(.icon-line) {
    fill: var(--ant-color-primary-hover);
    stroke: var(--ant-color-primary-hover);
  }
  :global(.icon-shape) {
    stroke: var(--ant-color-primary-hover);
  }
}

.iconButton[disabled], .iconButton[disabled] svg {
  color: var(--ant-color-text-disabled);
  fill: var(--ant-color-text-disabled);
  :global(.icon-line) {
    fill: var(--ant-color-text-disabled);
    stroke: var(--ant-color-text-disabled);
  }
  :global(.icon-shape) {
    stroke: var(--ant-color-text-disabled);
  } 
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";