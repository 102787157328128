.toolbar {
  margin-bottom: 0.5rem;
  &Left {
    display: flex;
    gap: 0.5rem;
  }
}

.showDetail {
  border-bottom: 1px dotted;
}

.highlightSubstr {
  font-weight: var(--ant-font-weight-strong);
}

.usersTable {
  :global(.ant-table-filter-trigger) {
    margin: 0 0.5rem 0 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1.5rem;
    justify-content: center;
  }


  :global(.ant-table-tbody > tr > td) {
    word-break: normal;
    padding: 0.3rem 0 0.3rem 0.8rem;
    
    @media @display-md {
      padding: 0.5rem 5px 0.5rem 0.8rem;
    }
  }

  :global(.ant-table-filter-column > .ant-table-column-title) {
    margin-right: 1.8rem; 
   } 
}

.userLink {
  padding: 0 0.25rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";