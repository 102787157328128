.positionReference {
  z-index: -90000;
  width: 100%;
  display: flex;
  visibility: hidden;
}

@media @display-lg {
  .document.auto, .document.narrow {
    :global(.ql-editor) p {
      line-height: 160%;
    }
  }
  .document.medium {
    :global(.ql-editor) p {
      line-height: 180%;
    }
  }
  .document.wide {
    :global(.ql-editor) p {
      line-height: 200%;
    }
  }
}

.lastTutorialTooltip :global(.icon-button) {
  display: none;
}

.secondaryEditor :global(.ql-editor) {
  background-color: var(--ant-color-fill-tertiary);
  h4 span, h4 search {
    background-color: var(--ant-color-fill-tertiary);
    &:hover {
      background-color: var(--ant-color-fill-tertiary);
    }
  }
}

.minVideo {
  right: 1rem;
  z-index: 10;
  padding-top: 5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";