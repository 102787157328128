.new-user-form {
  &__primary-inputs {
    .ant-form-item-label {
      flex: 0 0 30%;
      padding: 8px 0 0;
      @media @display-sm {
        padding: 0;
      }
    }
  }

  &__primary-inputs-credit {
    border-radius: 45px;
    width: 50%;
  }

  &__additional-input.ant-form-item {
    .ant-form-item-label {
      @media @display-sm {
        flex: 0 0 45%;
      }
    }
  }  
  
  &__additional-input--inline.ant-form-item {
    flex-wrap: nowrap;
    padding-right: 20px;
    .ant-form-item-label {
      flex: 0 0 75%;
      @media @display-sm {
          flex: 0 0 55%;
      }
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";