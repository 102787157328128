.collapsedToolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: var(--font-size-tiny);
  background-color: var(--ant-color-bg-container-disabled);
  border: 1px solid var(--ant-color-border);
  border-bottom-left-radius: 5px;

  @media @display-md {
    font-size: var(--ant-font-size);
  }
}

.collapsedToolbar>.icon-button {
  svg {
    height: 0.9em;
    width: 0.9em;

    &:hover {
      fill: var(--ant-color-primary-hover);
    }
  }
}

.selectZoom {
  &:hover {
    color: var(--ant-color-primary-hover);
  }

  &Dropdown:global(.ant-select-dropdown) {
    border-radius: 5px;
  }
}

.dividerOfCollapsedToolbar {
  width: 60%;
  min-width: 60%;
  margin: 0;
}

.dividerHorizontal {
  width: 90%;
  min-width: 90%;
  margin: 5px auto;
}

.expandedToolbar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: absolute;
  z-index: 16;
  font-size: var(--font-size-tiny);
  background-color: var(--ant-color-bg-container-disabled);
  border-bottom-left-radius: 5px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--ant-color-border);
  padding: 4px;

  .controlIcons {
    margin-right: 5px;
  }
}

.controlIcons {
  display: flex;

  input {
    font-size: var(--font-size-tiny);
    width: 60px;
    border-radius: 20px;
  }
}

.toolbarArrow {
  &:hover {
    color: var(--ant-color-primary-hover);
  }

  &Right {
    text-align: left;
  }
}

.zoomCount {
  color: var(--ant-color-text-disabled);
}

.zoomText {
  margin-left: 3px;
}

.arrowHorizontal {
  width: 100%;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--ant-color-text-base);
  margin: 0 -6px 0 5px;
}

.selectWithArrow {
  display: flex;
  align-items: center;
}

.hiddenToolbar {
  visibility: hidden;
}

.noneToolbar {
  display: none;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";