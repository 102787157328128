.mediaOrigin {
  display: flex;  
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: var(--ant-font-size-sm);
  line-height: 14px;
  cursor: default;
  border-radius: var(--ant-border-radius);
  padding: 0.25rem;
  padding-left: 0.5rem;
  background-color: var(--ant-color-fill-tertiary);

  &Label {
    display: flex;
    gap: 0.2rem;
    flex: 1;
    margin: 0 0.5rem;
  }

  :global(.anticon) {
    &:global(.anticon-check-circle) {
      color: var(--ant-color-success);
    }
    &:global(.anticon-exclamation-circle) {
      color: var(--ant-color-warning);
    }
    &:global(.anticon-close-circle) {
      color: var(--ant-color-error);
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";