.projectPanel {
  word-break: keep-all;
  table-layout: fixed;
  width: 100%;

  & :global(tr) {
    vertical-align: top
  }

  & :global(td) {
    width: auto;
    border-bottom: none;
  }
}

.tableHeadings {
  width: 7rem;
  min-width: 7rem;
  font-weight: var(--ant-font-weight-strong);
  padding: 0;
  text-align: justify;
  margin-left: 0.5rem;

  @media @display-sm {
    width: 9rem;
    min-width: 9rem;
  }
}

.aboutProject {
  width: 70%;
}

.notesHeadings {
  vertical-align: baseline;
  padding-top: 0.8rem;
}

.label {
  font-weight: var(--ant-font-weight-strong);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";