.announcement {
  padding: 0.2rem 0 0.5rem;
}

.icon {
  color: var(--ant-color-text-description);
  padding-left: 0;
}

.iconText {
  font-weight: var(--ant-font-weight-strong);
  font-size: var(--ant-font-size-sm);
}

.toolbar {
  margin: 1rem 0 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";