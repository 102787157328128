.abbreviationIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.description {
  width: auto;
  order: 1;
  flex: 1 0 100%;
  margin: 0.5rem 0 0.5rem 0;
  @media @display-xl {
    max-width: fit-content;
    order: initial;
    flex: auto;
  }
}

.hiddenInput {
  display: none;
}

.importButtonWithTooltip {
  @media @display-sm {
    margin-left: auto;
  }
}

.importButton:global(.ant-btn) {
  border: var(--border-style);
  box-shadow: none;

  &:not([disabled]) {
    background-color: var(--controls-button-color);

    &:hover {
      color: var(--controls-text-color-hover);
      background-color: var(--ant-color-primary-border);
    }
  }
}

span.importButtonText {
  display: none;

  @media @display-md {
    display: inline-block;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";