.user-search {
  display: flex;
  margin-top: 1rem;

  &--select {
    // Hack. Antd inserts some kind of wierd invisible span when user select item from
    // the dropdown. This spans somewhow brakes the baseline.
    align-items: center;
  }

  &--textfield {
    align-items: baseline;
  }

  @-moz-document url-prefix() {
    &--textfield {
      align-items: flex-start;
      .user-search__label {
        padding-top: 4px
      }
    }
  }

  &__input {
    flex: 1;
    margin-left: 0.5rem;
  }
    
  &__field {
    display: block;  
  }
  
  &__msg {
    color: var(--ant-color-error);
    margin-top: 0.25rem;
    margin-left: 0.5rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";