.speakersPage {
  :global(.ant-card-body) {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    @media @display-lg {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  :global(.ant-table-placeholder) {
    :global(.ant-table-cell) {
      border-bottom: none;

      :global(.ant-table-expanded-row-fixed) {
        margin: 0 -1rem;
        padding: 0;
      }

      &:hover {
        background-color: white;
      }
    }
  }
}

.speakersTable {
  display: flex;
  flex-wrap: wrap;

  @media @display-lg {
    width: 60%;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";