.colorBox {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: var(--ant-line-width) solid var(--shadow);
  border-radius: var(--ant-border-radius-sm);
}

.defaultColorWrapper, .defaultColorText {
  display: inline;
}

.defaultColorText {
  margin: 0 0.5rem 0 0.5rem;
}


@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";