.formButton:global(.ant-btn) {
  width: 100%;
  height: 2.5rem;
  margin-right: 0;
  margin-top: 1rem;
} 

.formInput:global(.ant-form-item) {
  margin-bottom: 1rem;
  @media @display-sm {
    margin-bottom: 0.75rem;
  }
}

.form {
  // Removes input background colour for Chrome autocomplete
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";