.uploadOverlay {
  position: absolute;
  right: 8px;
  bottom: 50px;
  z-index: 1001;
  font-size: var(--ant-font-size-sm);
  min-width: 215px;
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  background-color: white;

  &Header {
    background-color: var(--ant-color-bg-container-disabled);
    font-weight: var(--ant-font-weight-strong);
    border-radius: 20px 20px 0 0;
    padding: 0.5rem 0.3rem 0.5rem 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Body {
    overflow-y: auto;
    max-height: calc(30vh - 5rem);
  }
}

.uploadItem {
  display: flex;
  align-items: center;
  margin: 0.5rem 0.8rem 0.5rem 0.5rem;

  &Status {
    margin-right: 0.5rem;
  }

  &Progress {
    font-size: 80%;
  }

  &Icon {
    font-size: 1.2rem;
  }

  &Name {
    line-height: 2rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";