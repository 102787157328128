.creditInput {
  width: 40%
}

.buttons {
  margin-left: 0.5rem;
}

.creditButton:global(.ant-btn),
.creditButton:global(.ant-btn):hover {
  margin: 0.2rem;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border: none;

  :global(.anticon) svg {
    width: 1.7rem;
    height: 1.7rem;
    margin-bottom: -0.4rem;
    box-shadow: var(--box-shadow);
    border-radius: 50%;
  }

  :global(.anticon-plus-circle) {
    color: var(--ant-color-info);
  }

  :global(.anticon-minus-circle) {
    color: var(--ant-color-error);
  }
}

.row {
  margin-bottom: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";