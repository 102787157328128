.claimsSpin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.claimStatus {
  background-color: var(--ant-control-item-bg-hover);
}

.title {
  font-weight: var(--ant-font-weight-strong);
}

.switch {
  margin-top: 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";