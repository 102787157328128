.brand {
  width: 5.5rem;
  height: 2rem;
}

.apaLogo {
  display: flex;
  align-items: center;
  color: white;
  gap: 0.7rem;
  height: 100%;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
  &Bold {
    font-weight: var(--ant-font-weight-strong);
    max-width: 5rem;
  }
  &Text {
    font-size: 11px;
  }
  &Detail {
    display: flex;
    flex-wrap: wrap;
    max-width: 6rem;
  }
  @media  @display-sm {
    &Bold {
      max-width: 100%;
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";