.jobName {
  padding: 0 1rem;
  font-size: var(--ant-font-size-sm);
  max-width: 60%;
}

.jobsProgress :global(.ant-progress-inner) {
  // This is simply impossible to overwrite without !important GRRRRR
  width: 35px !important;
  height: 35px !important;
  font-size: 13px !important;
}

.jobInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    width: 1.5rem;
  }
}

.spin {
  display: flex;
  span {
    margin: 0 auto;
  }
}

.divider {
  margin-bottom: 1rem;
}

.content {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap-reverse;
  @media @display-lg {
    align-items: baseline;
    justify-content: space-between;
  }
}

.settings, .preview {
  flex-basis: 28rem;
}

.settings {
  :global(.ant-form-item) {
    margin-bottom: 0.5rem;
  }
}

.modal:global(.ant-modal) {
  @media @display-lg {
    min-width: 62rem;
  }
}

.smallerPreview img {
  width: 80%;
}

.preview {
  text-align: center;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";