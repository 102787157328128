.controls-button {
  display: flex;
  align-items: baseline;
  justify-content: center;
  &__label {
    flex: 1;
    margin-left: 0.5rem;
    font-size: var(--ant-font-size-sm);
    word-break: keep-all;
    white-space: normal;
    line-height: 20px;
  }

  &__btn {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 0 5px 0;
    padding: 10px;
    text-align: left;
    font-weight: var(--ant-font-weight-strong);
    font-size: 1rem;
    border: var(--border-style);
    border-radius: var(--ant-border-radius);
    box-shadow: none;
    background-color: var(--controls-button-color);
    &:hover {
      background-color: var(--ant-color-primary-border);
    }
    &:disabled {
      background-color: var(--ant-color-bg-container-disabled);
      color: var(--ant-color-text-secondary);
      pointer-events: none;
      cursor: not-allowed;
    }
    &:hover, &:hover svg {
      color: var(--controls-text-color-hover);
      stroke: var(--controls-text-color-hover);
    }
    &-dropdown {
      background-color: transparent;
      &:hover {
        background-color: var(--ant-color-fill-tertiary);
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
}

.beta-tag {
  width: 100%;
  flex: 0 0 55px;
  justify-content: center;
  background-color: var(--ant-color-info-hover);
  background-color: var(--ant-color-info-hover);
  color: var(--ant-layout-header-color);
  border-radius: 20px;
  padding: 1px 8px;
  margin-left: 10px;
  letter-spacing: 0.7px;
  font-size: 0.8rem;

  @media @display-sm {
    width: auto;
    flex: none;
  }
}

.busy-msg {
  margin-left: 0.5rem;
  font-size: var(--ant-font-size-sm);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";