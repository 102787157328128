.circleButton {
  height: 2rem;
  width: 2rem;
  padding: 0;
  border-radius: 50%;
  line-height: 1rem;
  font-size: 1rem;
  background-color: var(--ant-color-primary-border);
  &:hover {
    background-color: var(--ant-color-primary-border-hover);
  }
  svg path {
    fill: var(--ant-layout-header-color);
  }
  svg line {
    stroke: var(--ant-layout-header-color);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";