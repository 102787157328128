.sliders {
  :global(.ant-slider) {
    min-width: 7rem;
  }
  :global(.ant-slider-mark-text) {
    font-size: var(--font-size-tiny);
  }
}

.speed, .volume {
  display: flex;
  gap: 0.7rem;
  &Icon {
    height: fit-content;
    margin-top: 0.5rem;
    svg {
      height: 1.3rem;
    }
  }
}

.volumeIcon svg {
  height: 1.6rem;
}

.waveIcon svg, .videoIcon svg {
  height: 1.5rem;
  width: 1.5rem;
  &:hover, &:hover path {
    stroke: var(--ant-color-primary-hover);
    fill: var(--ant-color-primary-hover);
  }
}

.divider:global(.ant-divider) {
  margin: 0 0 0.5rem 0;
}

.view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &Buttons {
    display: flex;
    align-items: baseline;
    gap: 1rem;
  }
  &Button {
    padding: 0;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";