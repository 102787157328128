.creditInfo {
  min-width: 40%;
}

.teamCredit {
  font-size: 1.1rem;
  font-weight: 500;
}

.availableCredit {
  margin-bottom: 1rem;
}

.label {
  font-weight: var(--ant-font-weight-strong)
}

.details {
  margin-left: 1rem;
}

.alert {
  width: fit-content;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";