.section {
  display: flex;
  align-items: baseline;
  button {
    @media @display-sm {
      margin-right: 0.3rem;
    }
  }
}

.capitalsIcon svg {
  transform: translate(0, -10%);
  width: 1em;
  height: 1em;
  @media @display-lg {
    width: 1.1em;
    height: 1.1em;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";