.form {
  :global(.ant-row)  {
    @media @display-sm {
      flex-wrap: nowrap;
    }
  }
  
  :global(.ant-form-item, .ant-form-item-label) {
    max-width: 40%;
  }

  :global(.ant-form-item) {
    margin-bottom: 1rem;
  }

  :global(.ant-form-item .ant-form-item-control) {
    flex-basis: 50%;
  }

  .select:global(.ant-select.ant-select-in-form-item) {
    max-width: 60%;
  }
}

.card {  
  margin-bottom: 0.5rem;
  :global(.ant-card-head-title) {
    margin: 0.6rem 0 0 0;
    white-space: inherit;
  }
  
  h2 {
    margin-bottom: 0;
  }

  :global(.ant-card-body) {
    padding: 0 1.2rem 1.2rem;
  }
}

.sectionName {
  font-weight: var(--ant-font-weight-strong);
  margin-bottom: 0.4rem;
  margin-top: 0.7rem;
  &Danger {
    color: var(--ant-color-error);
  }
}

.divider {
  margin: 0 0 0.5rem 0;
}

.saveButton {
  margin-left: 0.3rem;

	@media @display-sm {
		margin-left: 0.5rem;
	}
}

.abandonProjectsDetail {
  margin: 0.3rem 0;
  font-size: var(--ant-font-size-sm);
  color: var(--ant-color-text-secondary);
}

.storageInfo {
  text-align: center;
  margin-top: 1rem;
}

.pageRow {
  max-width: 100%;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";