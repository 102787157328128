.shortcuts {
  display: flex;
  flex-direction: column;

  :global(.ant-drawer-body) {
    padding-top: 0.3rem;
  }

  :global(.ant-divider-horizontal) {
    margin: 0.8rem 0 0.3rem;
  }

}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";