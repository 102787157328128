.toolbar {
  display: flex;
  align-items: center;
  background-color: var(--ant-color-bg-container-disabled);
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 0.3rem;
  @media @display-md {
    padding-top: 0;
  }
  &Hidden {
    position: fixed;
    height: 3rem;
    z-index: 1;
    width: auto;
    padding-left: 1.1rem;
  }
  &Left {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    justify-content: space-between;
    margin-right: 0.5rem;
    &Wide {
      flex-basis: 30%;
    }
  }
  &Right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 0 0 auto;
  }
  :global(.ant-divider-vertical) {
    margin: 0;
    height: 1.3rem;
    background-color: var(--ant-color-text-secondary);
  }
  button {
    padding: 0 0.5rem;
  }
}

.sizeButtons {
  display: flex;
}

.middleDivider:global(.ant-divider-vertical) {
  margin: 0 1rem;
}

.showToolbarButton {
  padding: 0 0.5rem;
  height: 1.7rem;
  border-radius: 0 0 100% 100%;
  background-color: var(--ant-color-bg-container-disabled);
  transition: height 0.2s ease-in;
  box-shadow: var(--ant-box-shadow-tertiary);
  &:hover {
    height: 2.3rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";