.editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &WithSider {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
}

.buttonHighlight {
  background-color: var(--ant-color-error);
  color: white;
}

.documentRtl :global(.ql-editor) {
  direction: rtl;
  text-align: right;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";