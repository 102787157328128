.noConnection {
  position: absolute;
  left: 50%;
  top: 10px;
  z-index: 1001;
  border-radius: 20px;
  box-shadow: var(--box-shadow-light);
  padding: 5px 15px;
  background-color: white;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";