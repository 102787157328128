.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  :global(.waiting), p {
    margin-bottom: 0;
  }
}

.projectLink {
  font-size: var(--ant-font-size);
}

.form {
  :global(.ant-form-item-label) {
    font-weight: var(--ant-font-weight-strong);
    margin-bottom: 0.3rem;
  }
  :global(.ant-alert) {
    margin: 1rem 0;
  } 
}

.fields {
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }
}

.fieldLabel {
  font-weight: var(--ant-font-weight-strong);
  margin-bottom: 0.6rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";