.controls-section {
  margin-bottom: 0;

  &__head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: -0.2px;
    margin: 0;
    gap: 0.3rem;
  }

  &__title {
    flex: 1;
  }

  &--button-subtitle {
    button {
      display: flex;
      align-items: baseline;
    }
  }
  
  .tooltip-invisible {
    display: none;
  }

  .ant-divider-horizontal {
    margin: 12px 0;
    width: 95%;
  }

  .share-btn {
    display: flex;

    span {
      .ant-badge-count {
        margin-top: 6px;
        background: var(--ant-color-info);;
      }
    }     
  }
}


@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";