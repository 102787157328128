.templateControls {
  display: flex;
}

.readOnly {
  color: var(--ant-color-text-secondary);
  margin-left: 0.3rem;
}

.templateAlert {
  padding: 0.2rem 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";