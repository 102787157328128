.icon > svg {
  width: 1rem;
  height: 1rem;
}

.title {
  font-size: var(--font-size-large);
}

.column {
  flex: 1;
  @media @display-lg {
    width: 45%;
  }
  width: 100%;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";