.exportButton {
  display: flex;
  justify-content: end;
  margin-top: 0.5rem;

  button:global(.ant-btn) {
    margin-right: 0;
  }
}

.clearButton:global(.ant-btn) {
  border: none;
  margin: 0.2rem 0;
  box-shadow: none;
  padding: 0;
  &:hover {
    color: var(--ant-color-primary-hover)
  }
}

.teamUsageTable {
  .tableHeader {
    padding-left: 0.2rem;
  }

  :global(.ant-table-thead > tr > th) {
    padding-left: 0.5rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";