.teamSettings:global(.ant-form) {
    max-width: 50%;
    :global(.ant-form-item .ant-form-item-label) {
      font-weight: var(--ant-font-weight-strong);
      padding: 0;
    }
  }
  
.label {
  font-weight: var(--ant-font-weight-strong);
}

.settingsItem {
  margin-bottom: 0.5rem;
}
    
.settingsHeader:global(.ant-form-item) {
  margin-bottom: 0;
  :global(.ant-row) {
    flex-wrap: nowrap;
  }
}

.divider:global(.ant-divider-horizontal) {
  margin: 24px 0 16px;
}

.automaticDeletion {
  @media @display-md {
    margin-top: 0;
  }
}

.buttonSave {
  margin-left: 0.3rem;
  @media @display-sm {
    margin-left: 0.5rem;
  }
}

.alert {
  display: flex;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";