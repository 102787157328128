.authentification {
  font-size: 1.1rem;
  font-weight: var(--ant-font-weight-strong);
  margin-bottom: 1rem;
}

.token {
  :global(.ant-typography-copy) {
    margin-left: 0.5rem;
  }
  border: 1px solid  var(--ant-color-border);
  border-radius: var(--ant-border-radius);
  padding: 0.5rem;
  width: fit-content;
}

.popconfirm {
  width: 20rem;
  @media @display-sm {
    width: auto;
  }
}



@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";