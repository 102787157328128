.validatorMessage :global(.ant-form-item-control-input) {
  min-height: 0;
}

.tabs :global(.ant-tabs-tab) {
  padding: 0;
}

.modal :global(.ant-modal-content) {
  height: 50rem;
  overflow-y: scroll;
  border-radius: var(--ant-border-radius)
}

.modal :global(.ant-form-item-label >label) {
  display: block;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";