button.toolbarButton,
.toolbarButton button {
  font-size: 1rem;
  margin: 0;
  padding: 0 0.5rem;
  @media @display-sm {
    padding: 0 1rem;
  }
}

.clearSortIcon span {
  font-size: 0.9rem;
  font-weight: var(--ant-font-weight-strong);
  margin-left: 5px;
}

.table {
  width: 100%;
  @media @display-xl {
    width: 60%;
  }
  :global(.ant-table-thead > tr > th) {
    padding: 0 5px;
  }
  :global(.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table) {
    margin: 0;
  }
 
}


.footer {
  text-align: right;
}

.total {
  font-weight: var(--ant-font-weight-strong);
}

.deleted {
  font-style: italic;
}

.overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  @media @display-xl {
    gap: 3rem;
  }
  &Transcriptions {
    flex-grow: 1;
  }
}

.monthlyOverviewOfTeam {
  font-size: 1.1rem;
  font-weight: 500;
}

.tableTitle {
  margin-left: 1rem;
  font-size: 1.1rem;
}


@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";