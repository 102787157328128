.waveCanvas {
  width: 100%;
  height: 7.5rem;
  @media @display-md {
    height: 8.5rem;
  }
  border: 1px solid var(--ant-color-info-hover);
  border-radius: 5px;
  border-right: none;
  border-left: none;
  touch-action: none;
}

.canvasPanel {
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 7.5rem;
  @media @display-md {
    height: 8.5rem;
  }
  &Hidden {
    display: none;
  }
}
  
.canvasContainer {
  width: 100%;
  padding-left: 14px;
}

.waveLoading {
  // NOTE: None pointer events enable zooming of canvas when the mouse pointer is above the loading animation.
  pointer-events: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 10%;
  @media @display-sm {
    left: 40%;
  }
  
  &Text {
    margin-left: 0.7rem;
    color: var(--ant-color-text-secondary);
    font-style: italic;
    font-size: var(--font-size-tiny);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";