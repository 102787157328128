.leadPanel {
  min-width: 23rem;
  border-radius: 20px;
  background: white;
  box-shadow: var(--box-shadow);
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 29rem;
  z-index: 1;

  @media @display-sm {
    padding: 3rem 3.5rem;
    min-width: 27rem;
  }
}

.productLogo {
  height: 7.5rem;
  background: transparent url('resources/img/product-logo.svg') center;
  background-size: 16.5rem;
  background-repeat: no-repeat;
  margin: 0 auto 1rem auto;

  @media @display-sm {
    height: 9.5rem;
    background-size: 22rem;
    margin-bottom: 2rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";