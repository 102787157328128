.paymentScreenControls {
  margin-top: 2rem;
  text-align: center;
}

.redirection {
  font-size: var(--font-size-tiny);
  font-style: italic;
  margin-bottom: 2rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";