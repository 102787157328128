.problemItem {
  :global(.ant-collapse-content) {
    padding-left: 1.75rem;
  }
  white-space: pre-wrap;
  padding-top: 0.5rem;
}

.buttons {
  display: flex;
}

.showButton {
  margin: 0 0 0.5rem 0.5rem;
}

.tab {
  display: flex;
  align-items: center;
}

.icon {
  &Warning {
    color: var(--ant-color-warning);
  }

  &Error {
    color: var(--ant-color-error);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";