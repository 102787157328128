.shortcut {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  
  &Label {
    flex: 1 1 38%;
  }

  &Keys {
    display: flex;
    justify-content: flex-end;
    align-self: baseline;
    flex-wrap: wrap;
  }

  &Key {
    background-color: var(--ant-color-info-hover);
    border-radius: 5px;
    height: 1.5rem;
    padding: 0 5px;
  }

  &Plus {
    padding: 0 2px;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";