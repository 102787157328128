.reset {
  margin-bottom: 0.5rem;
}

.checkIcon {
  color: var(--ant-lime);
}

.closeIcon {
  color: var(--ant-red);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";