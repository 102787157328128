.projectTag {
  text-align: center;
  background-color: var(--ant-color-info-hover);
  border-radius: var(--ant-border-radius);
  display: inline-block;
  padding: 0.1rem 0.3rem;
  font-size: var(--ant-font-size-sm);
  margin-right: 0.5rem;
  max-width: 5rem;

  &ReadOnly {
    margin-left: 0.5rem;
    aspect-ratio: 1/1;
  }
}

.projectsTable {

  // FIXME: This is provisional solution as the class proj-results is not being attributed,
  // it needs to be examined why.
  :global(.ant-table-body #proj-results td) {
    border: none;
  }

  :global(.ant-card-body) {
    padding-bottom: 0;
    padding-top: 0;
  }

  :global(.ant-card-head-title) {
    overflow: visible;
  }

}

.trashed {
  color: var(--ant-color-text-disabled);

  a {
    color: var(--ant-color-text-disabled);
  }
}

.searchRow {
  display: none;
}

.column {
  &Centered {
    justify-content: space-evenly;
  }

  &Tags :global(.ant-table-column-title) {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    margin-right: 1.5rem;
  }

  &Projects {
    padding-right: 0.3rem;
  }
}

th.columnProjects,
th.columnTags,
th.columnDuration,
th.columnCreated {
  border-right: 1px solid var(--ant-color-border);
}

.filterDialog {
  display: flex;
  flex-direction: column;
  padding: 1.3rem 1.3rem 0 1.3rem;
}

.projectResults :global(td.ant-table-cell) {
  border-bottom: none;
}

.tagPanel {
  margin-bottom: 0.5rem;
}

.announcement {
  &Text {
    margin-top: 0;
    margin-bottom: 0;
  }

  &Alert {
    box-shadow: var(--box-shadow-light);
    border-radius: var(--ant-border-radius);
    margin-bottom: 0.7rem;
  }
}

.filterActive svg {
  fill: var(--ant-color-primary)
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";