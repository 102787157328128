.buttonPosition {
  width: 90%;
  margin: 5px;
}

.headerPosition {
  text-align: center;
  font-weight: var(--ant-font-weight-strong);
}

.position {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.captionSelect {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);
  border-radius: var(--ant-border-radius);
  padding: 0 6px 0 11px;
  width: 100%;
  height: 32px;
  cursor: pointer;
  caret-color: transparent;
}

.arrow {
  font-size: var(--ant-font-size-icon);
  color: var(--ant-color-text-quaternary);
  margin-right: 0.3rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";