.head {
  display: flex;
  overflow: hidden;
  height: 3rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: var(--ant-layout-header-bg);

  @media @display-md {
    padding: 0.5rem;
  }
}

.openBtn {
  flex: 0 0 2rem;
  padding: 0 0.25rem;
}

.brand {
  padding-left: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";