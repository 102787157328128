.dialog :global(.ant-tabs-tab) {
  padding-top: 0;
}

.replaceInfo {
  margin-bottom: 0.5rem;
  color: var(--ant-color-text-secondary)
}

.modalContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.label {
  width: auto;
  margin-bottom: 0.5rem;
  @media @display-sm {
    flex-basis: 25%;
    margin-bottom: 0;
  }
}

.form {
  align-items: flex-start;
  flex-direction: column;
  &Info {
    margin-bottom: 0.6rem;
  }
}

.selectInput :global(.react-select__control) {
  border-radius: var(--ant-border-radius);
  border-color: var(--ant-color-border);
  box-shadow: none;
  width: 20rem;
  &:global(--is-focused), &:hover, &:active {
    border-color: var(--ant-color-primary);
  }
}

.selectInput :global(.react-select__menu) {
  top: 2rem;
  position: absolute;
  border-radius: var(--ant-border-radius-lg);
  overflow: hidden;
}

.selectInput :global(.react-select__option--is-focused) {
  background-color: var(--ant-color-fill-tertiary);
  &:hover {
    background-color: var(--ant-color-bg-container-disabled);
  }
  &:active {
    background-color: var(--ant-color-primary-border);
  }
}

.header {
  display: grid;
  font-weight: var(--ant-font-weight-strong);
  cursor: move;
  width: 100%;
  :global(.anticon) svg {
    fill: var(--ant-color-text-base);
    font-size: 18px;
  }
  &AssignSpeaker {
    margin-bottom: 0.5rem;
  }
}

.speakerName {
  display: flex;
  gap: 0.5rem;
}

.optionLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.removeSpeaker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";