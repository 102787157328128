.hexagons {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('./img/hexagons.svg');
  background-size: cover;
  background-position: bottom left;
}

.langSelect {
  display: flex;
  justify-content: end;
  min-width: 23rem;

  :global(.ant-select .ant-select-selector) {
    width: 13rem;
  }

  @media @display-sm {
    min-width: 27rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";