 .jobName {
  padding: 0 1rem;
  font-size: var(--ant-font-size-sm);
  max-width: 60%;
}

.jobsProgress :global(.ant-progress-inner) {
  // This is simply impossible to overwrite without !important GRRRRR
  width: 35px !important;
  height: 35px !important;
  font-size: 13px !important;
}
  
.jobInfo {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  .icon {
    margin: 0 10px;
    width: 1.5rem;
    .iconLoading {
      width: 1.5rem;
    }
  }
}
  
.spin {
  display: flex;
  span {
    margin: 0 auto;
  }
}

.empty {
  text-align: center;
  color: var(--ant-color-text-secondary);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";