.deleteModal {
  span.bold {
    font-weight: var(--ant-font-weight-strong);
  }
  :global(span.anticon-warning svg) {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--ant-color-warning);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";