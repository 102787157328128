.inputs {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;

  :global(.ant-form-item) {
    width: 45%;
    margin-bottom: 0;
  }
}

.fullLengthButton:global(.ant-btn-sm) {
  min-width: auto;
  margin: 0 0.5rem;
}

.hyphen {
  margin: 0 1rem;
}

.alert {
  margin-top: 0.5rem;
}

.footer {
  display: flex;
  @media @display-sm {
    flex-direction: row;
    align-items: center;
    justify-content: right;
  }
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";