.switchableInput {
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
  gap: 0.5rem;
  div:global(.ant-form-item) {
    margin-bottom: 0;
  }
  :global(.ant-form-item-control) {
    margin-left: 0.5rem;
  }
  margin-bottom: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";