.appsReport {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.loading {
  margin-left: 1rem;
}

.appsList {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";