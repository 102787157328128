.contextMenu :global(.ant-dropdown-menu-item-icon) {
  font-size: 1em;
}

.captionEndItem {
  gap: 0.6rem;
}

.formatTextItem :global(.ant-dropdown-menu-submenu-title) {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.divider {
  margin: 0;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";