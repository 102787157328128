.controls {
  display: flex;
  justify-content: flex-end;
}

.inProgress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.errorMessage {
  color: var(--ant-color-text-secondary);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";