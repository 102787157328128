.speakerSign {
  border-bottom: 0.3rem solid var(--ant-color-text);
  width: 0.5rem;
  height: 1rem;
  &.forceDisplay {
    border-color: var(--ant-color-primary-hover);
  }
  &Container {
    width: 0.7rem;
  }
}

.speakerSignContainer.disabled, .speakerSign.disabled :global(span.ant-badge) {
  cursor: not-allowed;
} 

.deleteButton:hover {
  color: var(--ant-color-error-active);
}

.addButton svg {
  width: var(--ant-font-size-sm);
  color: var(--ant-color-text-disabled);
}

.forceDisplay svg {
  color: var(--ant-color-primary-hover);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";