.table {
  @media @display-md {
    width: 50%;
  }

  @media @display-xl {
    width: 33%;
  }

  :global(.ant-table-thead > tr > th) {
    padding: 0 5px;
  }
}

.teamInfo {
  margin-bottom: 0.5rem;

  @media @display-sm {
    display: flex;
    justify-content: space-between;
  }
}

.title {
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 0;
    margin-left: 0.3rem;
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.label {
  font-weight: var(--ant-font-weight-strong);
}

.addMemberButton {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.planUsage {
  @media @display-md {
    max-width: 50%;
  }

  @media @display-xl {
    max-width: 35%;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";