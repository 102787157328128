.fileDragger {
  font-weight: var(--ant-font-weight-strong);
  p {
    font-size: var(--ant-font-size-sm);
    margin-bottom: 0;
  }
  &:global(.ant-upload-drag) {
    border-radius: 20px;
    &:hover, &:active, &:focus {
      background-color: var(--ant-color-info-bg);
    }
    & :global(.ant-upload-btn) {
      padding: 8px 0 10px 0;
    }
  }

  &Hint {
    font-size: var(--font-size-tiny);
  }
  &Header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.25rem;
  }
  &Icon svg {
    color: var(--ant-color-primary);
    margin-right: 10px;
    height: 2rem;
    width: 2rem;
  }
  // ~ styles subsequent sibling, impossible to assign class to .ant-upload-list 
  ~ :global(.ant-upload-list .ant-upload-list-item-info) {
    background-color: var(--ant-color-fill-tertiary);
    :global(.ant-btn) {
      background-color: var(--ant-color-fill-tertiary);
      border: none;
      box-shadow: none;
      opacity: 1;
      margin-right: 0;
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";