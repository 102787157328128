.draggableModal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  border-radius: var(--ant-border-radius-lg);
  padding: 16px 30px;
  box-shadow: var(--box-shadow);
  width: 340px;
  z-index: 1000;

  @media @display-sm {
    width: 500px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.headerTitle {
  color: var(--ant-color-text-base);
  cursor: move;
  width: 100%;
}

.closeIcon {
  color: var(--ant-color-text-secondary);
  position: relative;
  left: 1.3rem;
  padding: 0.25rem;
  border-radius: var(--ant-border-radius-sm);
  bottom: 0.5rem;

  &:hover {
    color: var(--ant-color-text-secondary);
    background-color: var(--ant-color-fill-tertiary);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";