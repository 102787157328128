.tutorialModal {
  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.primaryButton {
  font-size: 16px;
  min-width: 15rem;
}

.text {
  margin-bottom: 0;
}

.image {
  margin-bottom: 1rem;
  width: 4rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";