.dropdownForm {
  label {
    font-weight: var(--ant-font-weight-strong);
    margin-left: 0.5rem;
  }
  &Tooltip p {
    color: var(--ant-color-white);
  }
}

.advancedSearch {
  background-color: var(--ant-color-fill-tertiary);
  margin-bottom: 0.5rem;
  border-radius: var(--ant-border-radius);
  padding: 1rem 0;
  &Header {
    display: flex;
    justify-content: space-between;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    letter-spacing: 0.7px;
    font-size: var(--font-size-tiny);
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem;
}

.infoIcon {
  font-size: var(--ant-font-size-lg);
}

.checkbox {
  display: flex;
  align-items: baseline;
  :global(.ant-form-item-control-input-content) {
    border-bottom: none;
  }
  label {
    font-weight: var(--ant-font-weight);
  }
}

.formInput :global(.ant-form-item-control-input-content) {
  border-bottom: solid 1px var(--ant-color-text-secondary);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";