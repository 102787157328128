.transcribedTotal {
  color: var(--text-color);
  font-weight: var(--ant-font-weight-strong);
  text-align: end;
  p {
    margin-bottom: 0;
    padding: 0.5rem;
  }
}

.monthlyOverview {
  font-size: 1.1rem;
  font-weight: 500;
}

.transcribedOverview {
  flex-grow: 1;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";