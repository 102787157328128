.editorToolbar {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  flex: 1;
  align-self: normal;
  margin: 0 0.5rem;
  align-items: center;
}

.deleteIcon :global(.anticon:hover) {
  color: var(--ant-color-error-hover);
}

.projectNameInput {
  border: none;
  background-color: white;
  font-weight: var(--ant-font-weight-strong);
  min-width: 9rem;
  box-shadow: var(--box-shadow-light);

  @media @display-md {
    max-width: 50rem;
  }
}

.shareIcon :global(.anticon) {
  font-size: var(--ant-font-size-lg);
  color: var(--ant-layout-header-color);

  &:hover {
    color: var(--header-color-hover);
  }
}

.savedLabel {
  white-space: nowrap;
  font-size: var(--font-size-tiny);

  @media @display-sm {
    font-size: var(--ant-font-size);
    margin-right: 1rem;
  }
}

.deleteModal {
  &Title {
    display: flex;
    gap: 0.5rem;
  }

  &Content {
    display: flex;
    justify-content: space-around;
  }
}

.readOnlyTag {
  display: flex;
  gap: 0.3rem;
  background-color: var(--ant-color-info-hover);
  padding: 0.3rem 0.5rem;
  border-radius: var(--ant-border-radius);

  &Text {
    display: none;

    @media @display-md {
      display: block;
    }
  }
}

.ratingButton {
  margin-right: 0.5rem;

  span:global:not(.anticon) {
    display: none;

    @media @display-md {
      display: inline;
    }
  }

  &:disabled {
    background-color: var(--ant-color-info);
    color: white;
    cursor: default;
  }
}

.wiggleAnimation {
  animation: wiggle 0.4s ease-in 2;
  animation-delay: 1s;
}

@keyframes wiggle {
  0% {
    transform: rotate(3deg);
  }

  25% {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(2deg);
  }

  75% {
    transform: rotate(-1.5deg);
  }

  100% {
    transform: rotate(3deg);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";