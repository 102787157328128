.menuButton {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
}

.menuIcon svg {
  height: 1.2rem;
  width: 1.2rem;
  g {
    fill: var(--ant-layout-header-color);
  }
  &:hover g {
    fill: var(--header-color-hover);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";