.tags {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: white;
  border-radius: var(--window-border-radius);

  .dropdownItem {
    margin-bottom: 5px;
  }

  :global(.anticon) {
    margin-left: 0.5rem;
  }
}

.userTag {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  font-size: 0.9rem;
  button {
    margin: 0 0.3rem;
  }
}

.dropdownForm {
  height: 130px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }
}

.noTags {
  font-style: italic;
  margin-top: 25%;
}

.newTag {
  background: white;
  border: 2px dashed var(--ant-color-border);
  font-weight: var(--ant-font-weight-strong);
  font-size: var(--font-size-tags);
  letter-spacing: 0.5px;
  text-transform: none;
  cursor: pointer;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";