.button {
  padding: 0.3rem;
  font-size: var(--font-size-tiny);
  min-width: 2rem;
  min-height: 2rem;
  border: 2px solid var(--shadow);
  border-radius: 0.5rem;
}

.defaultButton {
  border: 2px solid var(--ant-color-border);
  color: var(--shadow);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";