.stripeRows {
  :global(.ant-table-body) {
    background-color: var(--ant-color-fill-tertiary);

    :global(.ant-table-cell-fix-right) {
      background-color: var(--ant-color-fill-tertiary);
    }
  }

  tr:nth-child(2n) {
    background-color: white;

    :global(.ant-table-cell-fix-right) {
      background-color: white;
    }
  }

  tr>td:first-child {
    padding-left: 15px;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";