.account-page-card {
  width: 100%;
  &__account {
      &-text {
        display: flex;
        flex-wrap: wrap; 
        justify-content: space-between;
        align-items: center;         
        font-size: var(--ant-font-size);
        font-weight: var(--ant-font-weight-strong);
      }
    } 
    
    .ant-card-body {
    padding-top: 0;
  }
    
  &__content {
    padding: 0;
    .ant-tabs-tab {
      text-transform: uppercase;
      padding: 5px 0 12px 0;
    }
    .ant-tabs-nav-wrap {
      padding-top: 1rem;
    }
  }
  .account-section {
    @media @display-sm {
      margin: 0 0.5rem 1.5rem;
      padding: 5px;
    }

    &.billing-info {
      margin-bottom: 1rem;
      @media @display-md {
        margin: 0 0.5rem 1.5rem 2rem;
      }
      @media @display-xxl {
        margin-left: 5rem;
      }
    }

  .ant-table {
    font-size: var(--font-size-tiny);
    margin: 0 !important;
    @media @display-md {
      font-size: var(--ant-font-size);
    }
    &-cell {
      padding: 3px;
    }
    &-thead {
      word-wrap: break-word;
    }
  }
    
    &__title {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 0 0 10px 0;
      span {
        margin-right: 5px;
      }
    }

    &__row {
      display: flex;
      line-height: 1.8;
      .label {
        width: 55%;
        font-weight: 500;
        @media @display-sm {
          width: 45%;
        }            
      }
      .details {
        width: 45%;
        @media @display-sm {
        width: 55%;
        }
      }
      &.purchase-credit {
        font-weight: var(--ant-font-weight-strong);
        margin-top: 12px;
      }
      &.credit-description {
        line-height: 1.4;
        font-size: 0.9rem;
        margin-right: 1rem;
      }
      &.minutes {
        margin-top: 12px;
        .label {
          align-self: center;
          width: 55%;
          @media @display-sm {
            width: 45%;
          }
        }   
        .input {
          width: 120px;
          border-radius: 45px;
        }        
      }
      &.price {
        margin-top: 10px;
        .label {
          width: 55%;
          @media @display-sm {
            width: 45%;
          }
        }
        .price-details {
          width: 120px;
        }
      }
    }

    .overview-divider{
      display: none;
      @media @display-xl {
        display: flex;
      }
    }

    button.ant-btn.transparent {
      color: var(--ant-color-primary);
      background-color: transparent;
      text-align: left;
      border: none;
      box-shadow: none;
      padding: 0;

      &:hover {
        color: var(--ant-color-text-base);
      }
      &.transcribed-overview {
        margin-top: 12px;
      }
      & span {
        font-size: var(--font-size-tiny);
          @media @display-md {
            font-size: var(--ant-font-size);
        }
      }
    }
  }
  
  &__tab-auth {
    .account-section {
      width: 95%;
      &.change-psw {
        width: 100%;
      }
      
      &__title {
        font-size: 1.1rem;
        font-weight: 500;
        margin: 0 0 10px 0;
        span {
          margin-right: 5px;
        }
      }

      &, &.change-psw {
        @media @display-sm {
          width: 90%;
        }
        @media @display-md {
          width: 85%;
          padding: 15px;
          margin: 0 1rem 2rem;
        }
        @media @display-lg {
          width: 65%;
        }
        @media @display-xl {
          width: 45%;
        }
        @media @display-xxl {
          width: 30%;
          margin: 0 1.2rem 2rem;
        }
      }
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";