@keyframes fadePlaybackIcon {
  0% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.display-none {
  display: none;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-bottom: 1.5px solid var(--ant-color-fill-tertiary);

  @media @display-sm {
    display: flex;
  }

  @media @display-lg {
    display: block;
  }

  &.display-none {
    display: none;
  }

  &__canvas {
    position: relative;
    height: 100%;
  }

  &__playback-icon {
    position: absolute;
    top: 45%;
    left: 47%;

    span {
      border-radius: 50%;
      background-color: white;
      color: black;
      font-size: 3rem;
      opacity: 0;
      animation: fadePlaybackIcon 1.5s;
    }
  }

  &__playback-icon--minimized {
    span {
      font-size: 1rem;
    }
  }

  video {
    height: 100%;
    width: auto;
  }
}

.video-min .video-player {
  display: block;
}

.captions {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 1rem;
  z-index: 5;

  .caption-lines {
    display: flex;
    flex-direction: column;
    &--hidden {
      visibility: hidden;
    }
  }
}

.caption-line {
  color: white;
  font-size: 1rem;
  padding: 0 0.5rem;
  white-space: nowrap;
  cursor: grab;
  &.disabled {
    cursor: default;
  }
}

.caption-line-center {
  align-self: center;
}
.caption-line-left {
  align-self: flex-start;
}
.caption-line-right {
  align-self: flex-end;
}
.caption-line-grabbing {
  cursor: grabbing;
}

.control-elements {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media @display-lg {
    height: calc(100% - 2.3rem); // height has to be shorten by the top positioning
  }

  &--video {
    display: flex;
  }

  .video-button {
    margin: 4px 4px 0 4px;
    z-index: 10;
    background-color: var(--ant-color-bg-container-disabled);
    span {
      font-size: 1.4rem;
    }
    svg path {
      fill: var(--ant-color-text);
    }
    svg line {
      stroke: var(--ant-color-text);
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";