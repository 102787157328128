.afterDays {
  margin-left: 0.3rem;
}

.daysInput {
  width: 70px;
}

.header {
  display: block;
  margin-bottom: 0.2rem;
}

.italics {
  font-style: italic;
}

.label {
 margin-right: 0.5rem;
 @media @display-sm {
   margin-right: 0; 
  }
}

.row {
  display: flex;
  align-items: baseline;
}

.infoCircle {
  vertical-align: text-top;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";