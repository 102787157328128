.activationScreenConfirmation {
  text-align: center;
}

.sendAgainText {
  .sendAgainButton {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      color: var(--ant-color-primary);
    }
  }
}

.lookingForwardText {
  padding-top: 1.5rem;
}

.beeyLogo {
  height: 5rem;
  margin-bottom: 1.5rem;
}

.activationTitle {
  font-weight: normal;
}

.userEmail {
  font-weight: var(--ant-font-weight-strong);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";