.creditSection {
  display: flex;
  justify-content: space-between;

  &Info {
    font-size: var(--ant-font-size-sm);
    margin-bottom: 0.5rem;
  }

  &Label {
    margin-right: 5px;
  }
}

.collapse :global(.ant-collapse-item) {
  :global(.ant-collapse-header) {
    padding: 0 0 0.5rem 0;
  }
  :global(.ant-collapse-arrow) {
    margin-right: 8px;
  }

  :global(.ant-collapse-content .ant-collapse-content-box) {
    padding: 0;
  }
}

.label label:global(:not(.ant-radio-wrapper, .ant-checkbox-wrapper)) {
  font-weight: var(--ant-font-weight-strong);
}

div.radioGroup:global(.ant-form-item) {
  margin-bottom: 0.2rem;
 :global(.ant-form-item-row) {
  display: grid;
  grid-template-columns: 1fr 2.5rem;
  align-items: center;
  }
}

.loadingIndicator {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

div.originsList:global(.ant-form-item) {
  margin-bottom: 0.3rem;
}

.divider:global(.ant-divider) {
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
}

.footer {
  margin-top: 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";