.buttons {
  padding: 1.3rem 0;
  display: flex;
  justify-content: end;
  gap: 0.6rem;

  :global(.ant-btn-primary) {
    margin-right: 0;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";