.fulltext-search-tag {
  background-color: var(--ant-color-info-hover);
  border: none;
  color: var(--ant-layout-header-color);
  &__wide {
    display: none;
  }
  &__narrow {
    display: inline-block;
  }
  @media @display-md {
    &__wide {
      display: inline-block;
    }
    &__narrow {
      display: none;
    }
  }
}

.ant-btn.fulltext-reset-button {
  font-size: var(--font-size-tiny);
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0 0.5rem;
  margin-right: 5px;
  @media @display-sm {
    padding: 0 1rem;
  }
  &:hover, &:focus {
  background-color: transparent;
  }
  &:hover span {
    color: var(--ant-color-primary-hover);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";