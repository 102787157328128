// NOTE: Many styles overriding ant in this file were added heuristically
// by @podlomar to fix bugs caused by the combination of menu and sider.

.dashmenu {
  display: relative;
  margin-right: 0.3rem;

  &__tail {
    width: 100%;
    height: 2.5rem;
    margin-left: 0.7rem;
    background-image: url('/src/resources/img/product-logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 1rem;

    &--small {
      height: 2rem;
      background-image: url('/src/resources/img/beey.svg');
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-menu {
    letter-spacing: 1px;
    font-weight: var(--ant-font-weight-strong);
    padding: 0rem;

    .ant-menu-item-divider {
      margin: 1rem 0 0.4rem 0;
      width: 95%;
    }
  }

  .anticon {
    width: 40px;
    height: 40px;

    &>svg {
      height: 1.2rem;
      width: 1.2rem;
      position: relative;
      top: 0.15rem;
    }

    &+span {
      margin: 0;
    }
  }

  .ant-menu-submenu {
    transition: none;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 0 0 0.5rem;
    transition: none;

    &:hover {

      span,
      .anticon,
      i,
      svg g {
        color: var(--ant-color-primary-hover);
        stroke: var(--ant-color-primary-hover);
      }
    }

    a:hover {
      color: var(--ant-color-primary-hover);
    }
  }

  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-submenu.ant-menu-submenu-selected {
    color: var(--controls-text-color-hover);
    background-color: var(--ant-color-primary-border);
    border-radius: 0 45px 45px 0;
    box-shadow: var(--box-shadow);

    .ant-menu-title-content {
      color: var(--controls-text-color-hover);
    }

    &:hover {
      background-color: var(--ant-color-primary-border-hover);

      span,
      .anticon,
      i {
        color: var(--controls-text-color-hover);
      }
    }

    a,
    a:hover {
      color: var(--controls-text-color-hover);
    }

    &:active {
      background-color: var(--ant-color-primary-border);
    }
  }

  .ant-menu.ant-menu-inline-collapsed {
    .ant-menu-submenu-title {
      padding: 0 0 0 0.5rem;
    }

    &>.ant-menu-item.ant-menu-item-selected,
    &>.ant-menu-submenu.ant-menu-submenu-selected {
      padding: 0 !important;
      border-radius: 50%;
      box-shadow: var(--box-shadow);
      width: 40px;
      height: 40px;
      margin-left: 0.5rem;

      .ant-menu-submenu-title {
        padding: 0;
      }
    }
  }

  .ant-menu-inline.ant-menu-sub .ant-menu-item .ant-menu-title-content {
    padding-left: 1.7rem;
  }

  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item.ant-menu-item-selected,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu.ant-menu-submenu-selected {
    box-shadow: var(--box-shadow);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";