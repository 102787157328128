.table {
  margin-top: 0.5rem;
}

.formItemHidden {
  display: none;
}

.icon svg {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}

.switch:global(.ant-form-item) {
  margin-bottom: 0;
}

.button {
  width: fit-content;
  margin: 0 0 0 auto;
}



@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";