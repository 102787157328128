.border {
  border: solid 3px var(--ant-color-info);
  border-radius: 1.5rem;
  margin: 0 auto;
}

.card {
  background-color: var(--controls-button-color);
  width: 17rem;
  height: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem;
  

  &:global(.ant-card) {
    margin-top: 0.3rem;
  & :global(.ant-card-body) {
    max-width: 17rem;
  }
  }

  button {
    margin-top: 1rem;
  }
}

.activeCard {
  background-color: var(--active-card-background);
}

.price {
  position: relative;
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: var(--font-size-large);
}

.infoBilling {
  position: absolute;
  top: 1.5rem;
  font-size: var(--font-size-tiny);
}

.icon {
  margin-right: 0.3rem;
}

.icon:global(.anticon-info-circle) svg {
  fill: var(--ant-color-text-base);
}

.features {
  height: 13rem;
  margin-top: 3rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";