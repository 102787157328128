.cardHeader {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: baseline;
  justify-content: space-between;
  span {
    font-size: var(--ant-font-size-sm);
  }
  h2 {
    margin-right: 0.5rem;
  }
}

.alert {
  width: fit-content;
  padding: 0.2rem 0.8rem;
  margin: 0.5rem 0;
}

.availableGroupCredit {
  position: relative;
  font-size: var(--ant-font-size-sm);
  top: -0.5rem;
  color: var(--ant-color-text-secondary);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";