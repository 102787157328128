.result {
  margin: 0;
  white-space: pre-wrap;
}

.actionButton:global(.ant-btn-primary) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  min-width: 1.5rem;
  margin-left: 0.3rem;
  padding: 0;
  
  @media @display-md {
    height: 1rem;
    min-width: 1rem;
    width: auto;
  }

  :global(.anticon) {
    @media @display-md {
      font-size: 0.7rem;
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";