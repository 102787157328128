.urlInput {
  display: flex;

  &Label {
    font-weight: var(--ant-font-weight-strong);
    font-size: var(--ant-font-size-sm);
  }

  &Button:global(.ant-btn) {
    border-radius: 0 var(--ant-border-radius) var(--ant-border-radius) 0;
  }

  &Error {
    color: var(--ant-color-error);
    margin: 0;
  }
}

.fileDraggerDisabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.filesUpload {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orDivider:global(.ant-divider) {
  margin: 0.2rem 0;
  span {
    font-weight: var(--ant-font-weight);
    font-size: var(--font-size-tiny);
  }
} 

.mediaOriginsView {
  max-height: 7rem;
  overflow: auto;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";