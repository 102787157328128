button.editor-width-btn {
  display: none; 
  right: 1rem;
  z-index: 20;
  margin: 4px;
  @media @display-lg {
    position: absolute;
    top: 2px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      height: 1.3rem;
      width: 1.3rem;
    svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.editor-width-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0;
    color: var(--ant-color-text-base);
  }
  button {
    transform: scale(0.8)
  }
}

.editor-width-selector {
  .ant-radio-button-wrapper{
    border: none;
    padding: 0 7px;
    &:not(:first-child) {
      border-left: 1.5px solid var(--ant-color-border);
      // in ant design all radio buttons apart from the first one have a ::before 
      &::before{
        content: none;
      }
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";