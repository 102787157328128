.header {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  line-height: 1rem;
  padding: 0.5rem;

  @media @display-sm {
    flex-wrap: nowrap;
  }

  // NOTE: Do not remove. Must be here for APA theme.
  &Button {
    margin: 0 0 0 0.25rem;
    border: var(--header-button-border);
  }
}

.menuButton {
  flex: 0 0 1rem;
  order: 1;
}

.brand {
  order: 2;
  margin-right: 0.3rem;
}

.controls {
  display: flex;
  gap: 0.25rem;
  order: 4;
}

.toolbar {
  flex: 1 1 100%;
  order: 5;
  color: var(--ant-layout-header-color);

  @media @display-sm {
    order: 3;
    flex: 1 1 auto;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";