.billingInfo {
  display: grid;
  gap: 0.5rem;
}

.divider:global(.ant-divider) {
  margin-bottom: 0.2rem;
  font-size: var(--ant-font-size);
}

.reverseCharge {
  font-size: var(--ant-font-size-sm);
}

.editButton {
  text-align: end;
  margin-top: 1.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";