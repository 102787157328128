.button:global(.ant-btn.ant-btn-primary) {
  margin-left: 1rem;
  box-shadow: none;
  background-color: var(--controls-button-color);
  color: var(--controls-text-color-hover);
  &:hover {
    color: var(--controls-text-color-hover);
    background-color: var(--ant-color-primary-border);
  }
}

.spinnerIcon {
  margin-right: 0.5rem;
}

span.buttonText {
  display: none;

  @media @display-md {
    display: inline-block;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";