/* NOTE: This file was created by modifying css from google fonts so that
   the woff2 files are served from our server and by adding support for special
   symbols used in editor.
*/

@font-face {
  font-family: 'beey-editor';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url('./fonts/OpenSans-VariableFont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116, U+1F00-1FFF, U+0370-03FF, U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB, U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF ;
}

/* NOTE: Font weight 400 does not apply if not defined specifically, probably caused by: 
"Some variable fonts do not map exact values like 400 (normal) or 700 (bold) to their 
internal weight axis. Instead, these values may fall outside the usable range due 
to incorrect or missing mappings in the font file.*/

@font-face {
  font-family: 'beey-editor';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/OpenSans-VariableFont.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116, U+1F00-1FFF, U+0370-03FF, U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB, U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF ;
}

@font-face {
  font-family: 'nbspace';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/open-sans-nbspace-extraBold.woff2') format('woff2');
  unicode-range: U+00A0;
}

@font-face {
  font-family: 'beey-editor';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  /*
    NOTE: font Symbola-subset-shapes was created from the font symbola using glyphhanger.
    It was added to support special characters in editor.
  */
  src: url('./fonts/symbola-subset-shapes.woff') format('woff');
  unicode-range: U+2300-2BFF;
}
