.table {
  :global(.ant-table-cell) {
    font-size: var(--font-size-tiny);

    @media @display-md {
      font-size: var(--ant-font-size);
    }
  }

  th {
    word-break: keep-all;
  }
}

.projectsOverview {
  @media @display-lg {
    width: 70% !important;
  }

  @media @display-xl {
    width: 50% !important;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";