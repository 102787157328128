.price {
  font-size: var(--font-size-tiny)
}

.feature {
  margin-bottom: 1rem;
  width: 100%;
}

.payAsYouGoFeature {
  align-items: baseline;
  margin-bottom: 0;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";