.asterisk {
  color: var(--ant-color-error);
  font-family: SimSun, sans-serif;
  vertical-align: super;
}

.confirmButton {
  display: block;
  margin-left: auto;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";