.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  margin-top: 1.5rem;
}

.orDivider:global(.ant-divider-horizontal) {
  margin: 1.5rem 0;
  span {
    font-size: var(--font-size-tiny);
    color: var(--ant-color-text-disabled);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";