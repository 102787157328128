.find-and-replace-drag {
  .search-input {
    .ant-form-item {
      margin-top: 8px;
    }

    .ant-form-item-control {
      @media @display-sm {
        flex: 0 0 0;
        margin-left: auto;
        min-width: 60%;
      }
    }

    &--find-mode {
      .ant-form-item-control {
        @media @display-sm {
          min-width: 75%;
        }
      }
    }

    &__replace {
      @media @display-sm {
        margin-top: 0;
      }
    }
  }

  .search-replaced {
    margin-top: 5px;
    margin-bottom: 1rem;
  }

  .search-finding-location {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;

    @media @display-sm {
      margin-top: 0;
    }

    &--no-result {
      color: var(--ant-color-error);
    }
  }

  .skip-search-result {
    color: var(--ant-color-text-secondary);
    padding-right: 0;

    &:hover {
      color: var(--ant-color-primary-hover);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--ant-color-bg-container-disabled);

      &:hover {
        color: var(--ant-color-bg-container-disabled);
      }
    }

    span {
      font-size: 1.3rem;
      padding-top: 5px;
    }
  }

  .search-find-replace-btn {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media @display-sm {
      margin-bottom: 1rem;
    }

    .ant-btn {
      margin-top: 10px;
    }

    .ant-btn[disabled] {
      margin-right: 0;
    }
  }

  .search-find-btn {
    display: flex;
    justify-content: space-between;

    @media @display-sm {
      width: auto;
      margin-bottom: 0;
      margin-top: 0;
    }

    .ant-btn {
      margin-right: 5px;

      @media @display-sm {
        margin-right: 10px;
      }
    }
  }

  .search-find__match-case {
    .ant-form-item-control {
      margin-top: 1rem;

      @media @display-sm {
        flex: 0 0 71%;
        margin-left: auto;
        margin-top: 0;
      }
    }

    &--find-mode {
      .ant-form-item-control {
        @media @display-sm {
          flex: 0 0 82%;
        }
      }
    }
  }

  .rule-icon {
    margin-right: 0.5rem;
  }

  .rule-added {
    color: var(--ant-color-success);
  }

  .button-add-rule {
    margin-left: 2rem;
  }

  .divider-replacement-rule {
    margin-bottom: 1rem;
  }

  .replacement-rule-text {
    margin-bottom: 1rem;
  }

  .replacement-rule-validation {
    line-height: 150%;
    margin-top: 0.5rem;
    font-size: var(--font-size-tiny);
    color: var(--ant-color-text-secondary);
  }
}

.find-and-replace-header {
  display: flex;
  gap: 1rem;
  
  button {
    font-size: var(--ant-font-size-lg);
    font-weight: 500;
    padding: 0;
    z-index: 10;
  }

  &__active-button {
    color: var(--ant-color-primary-hover);
  }

  .header--divider {
    height: 1.5em;
    z-index: 10;
  }

  .header--buttons {
    z-index: 10;
    margin-left: 1rem;
    margin-bottom: 12px;

    .header--find-btn {
      padding-left: 0;
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";