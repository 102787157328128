.speaker {
  margin-left: 0.5rem;
}

.speakerList {
  height: 200px;
  overflow: auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: baseline;
  cursor: move;
}

.hint {
  color: var(--ant-color-text-secondary);

  @media @display-sm {
    margin-top: 2rem;
  }
}

.speakerForm {
  :global(.ant-form-item) {
    margin-bottom: 0.2rem;
  }

  @media @display-sm {
    :global(.ant-form-item) {
      margin-bottom: 0.5rem;
    }
  }
}

.speakerFormItem {
  display: flex;
  align-items: center;

  :global(.ant-form-item) {
    margin-bottom: 0rem;
  }

  :global(.ant-select-single.ant-select-sm .ant-select-selector) {
    padding: 0 0.25rem;
  }
}

.closeIcon:global(.ant-modal-close-icon) {
  color: var(--ant-color-text-secondary);
  position: relative;
  left: 1.3rem;
  bottom: 0.5rem;
}

.conflict {
  margin-left: 1rem;
  color: var(--ant-color-warning);

  :global(.anticon) {
    margin-right: 0.3rem;
  }
}

.controlInput :global(.ant-form-item-control-input) {
  min-height: 3.5rem;
}

.colorBox {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: var(--ant-line-width) solid var(--shadow);
  border-radius: var(--ant-border-radius-sm);
}

.defaultColorText {
  margin: 0 0.5rem 0 0.5rem;
}

.defaultColorWrapper,
.defaultColorText {
  display: inline;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";