.highlightingImage {
  width: 100%;
  margin-bottom: 1rem;
}

.advancedSettings :global(.ant-collapse-item) {
  :global(.ant-collapse-header) {
    padding-left: 0;
  }
  & > :global(.ant-collapse-content) {
    background-color: var(--ant-color-fill-tertiary);
    border-radius: 10px;
    :global(.anticon-info-circle) {
      align-self: flex-start;
      color: var(--ant-color-info);
    }
  }
}

.formatAlert {
  margin-top: 2rem;
}

.documentationLink {
  margin-top: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";