.select {
  width: 15rem;
}

.dangerText {
  color: var(--ant-color-error);
  font-weight: var(--ant-font-weight-strong);
  margin-bottom: 0.4rem;
  margin-top: 0.7rem;
}

.divider {
  margin: 0 0 0.5rem 0;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";