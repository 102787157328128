.wave {
  display: flex;
  &Line {
    width: 0.5rem;
    opacity: 0;
    height: 5rem;
    margin: 1px;
    background-color: var(--ant-color-info-hover);
    animation: waveLine 1.5s linear infinite;
    border-radius: 20px;
  }
}

.waveLine:nth-child(2) {
  animation-delay: 0.1s;
}
.waveLine:nth-child(3) {
  animation-delay: 0.2s;
}
.waveLine:nth-child(4) {
  animation-delay: 0.3s;
}
.waveLine:nth-child(5) {
  animation-delay: 0.4s;
}
.waveLine:nth-child(6) {
  animation-delay: 0.5s;
}
.waveLine:nth-child(7) {
  animation-delay: 0.6s;
}
.waveLine:nth-child(8) {
  animation-delay: 0.7s;
}
.waveLine:nth-child(9) {
  animation-delay: 0.8s;
}
.waveLine:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes waveLine {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";