.rolesTable {
  @media @display-lg {
    width: 40%;
    padding-left: 1.2rem;
  }
}

.table {
  width: 100%;
}

.rolesModal {
  :global(.ant-form-item) {
    &-label label {
      font-weight: var(--ant-font-weight-strong);
    }
  }
}

.saveButton {
  display: block;
  margin: 1rem 0 0 auto;

  @media @display-md {
    margin-top: 0;
  }
}

.spinnerIcon {
  padding-right: 0.3rem;
}

.hiddenField {
  display: none;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";