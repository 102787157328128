.dragModal {
  .content {
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow: auto;
  }
}

.keyword {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}

.keywordText {
  width: fit-content;
  border-radius: var(--ant-border-radius);
  padding: 0 0.5rem;
}

.keywordTextHighlighted {
  box-shadow: var(--active-result-shadow);
}

.inactiveKeyword {
  color: var(--ant-color-text-secondary);
  box-shadow: none;
}

.activeKeyword {
  color: var(--ant-color-text-base);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";