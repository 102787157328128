.fulltext-search {
  display: flex;
  justify-content: center;

  &__input {
    .ant-input-search-button {
      border: var(--header-button-border);
    }

    .ant-input-affix-wrapper {
      border-color: white;
      box-shadow: var(--box-shadow-light);
    }

    .anticon {
      color: white;
    }
  }

  @media @display-md {
    justify-content: flex-end;
  }

  .ant-form-horizontal {
    display: flex;
    width: 320px;
    margin-top: 0.5rem;

    @media @display-sm {
      width: auto;
      margin-top: 0rem;
    }
  }

  .ant-form-item {
    width: 320px;
    margin: 0;

    @media @display-sm {
      width: auto;
    }
  }

  &__icon svg {
    font-size: var(--ant-font-size-sm);
    color: var(--ant-color-text-base);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";