.loader {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.transcribeModal {
  font-size: var(--ant-font-size-sm);

  :global(.ant-alert p) {
    font-weight: var(--font-weight-base);
  }

  :global(.ant-alert) {
    margin: 0.5rem 0;
  }

  :global(.ant-form-item) {
    margin-bottom: 0.75rem;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";