.saved-tags {
  width: 240px; 
  margin: 0 auto;
  padding: 0.5rem;
  .ant-checkbox-wrapper {
    margin-left: 0;
    border-radius: 5px;
  }
  &__initial, &__user {
    display: flex;
    flex-direction: column;
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
  }
  &__all {
    width: 100%;
    background: var(--ant-color-fill-tertiary);
    border-bottom: 1px solid white;
    transition: background 0.3s ease;
    margin-bottom: 5px;
    &.ant-checkbox-wrapper {
      font-size: 0.8rem;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--ant-color-fill-tertiary);
    margin-bottom: 4px;
    h4 {
      margin: 0;
      padding-left: 8px;
    }
    svg {
      transform: scale(0.8, 0.8);
    }
  }
  
  &__list {
    max-height: 600px;
    overflow-y: auto;
    .ant-list-empty-text {
      display: none;
    }
    .ant-form-horizontal {
      margin-left: 0.5rem;
      .ant-form-item {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
  &__input {
    margin-left: 23px;
    margin-top: 5px;
  }
  &__buttons {
    margin: 1rem 0.5rem 0.5rem 0;
    text-align: end;
    button {
      margin: 0 0.3rem;
    }
  }
  .ant-list-sm .ant-list-item {
    padding: 4px 16px;
  }
  &__item {
    &__text {
      cursor: default;
    }
    
    &__bin {
      &:hover {
        color:var(--ant-color-error);
      }
    }
  }
  
  .ant-table tbody > tr > td {
    border-bottom: none;
  }
}

.project-tags {
  font-size: var(--ant-font-size-sm);
  margin-top: 1rem;
  margin-left: 0.5rem;
  &__select.ant-select.ant-select-multiple {
    width: 100%;
    .ant-select-selection-item {
      border-radius: 20px;
    }
    .ant-select-selector {
      border-radius: 25px;
    }
  }
}


@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";