.hiddenField {
  display: none;
  margin-bottom: 0;
  .ant-form-item-control-input {
    min-height: 1rem;
  }
}

.spinnerIcon {
  padding-right: 0.3rem;
}

.label {
  margin-bottom: 0.25rem;
  font-weight: var(--ant-font-weight-strong);
}

.voiceSampleList {
  max-height: 20rem;
  overflow: auto;
}

.voiceSample {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
  align-items: center;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";