.toolbar {
  &Left {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    @media @display-sm {
      gap: 0.3rem;
    }
    gap: 0.1rem;
  }
}

button.toolbarButton,
.toolbarButton button {
  font-size: 1rem;
  margin: 0;
  padding: 0 0.5rem;

  @media @display-sm {
    padding: 0 1rem;
  }
  &[disabled] {
    color: var(--ant-color-text-disabled);
    cursor: not-allowed;
  }
}

.exportButtonWithTooltip {
  @media @display-sm {
    margin-left: auto;
  }
}

.clearSortIcon span {
  font-size: 0.9rem;
  font-weight: var(--ant-font-weight-strong);
  margin-left: 5px;
}

.deleteText {
  line-height: 100%;
}

.keyIcon svg {
  width: 1em;
  height: 1em;
}

.keywordsOnButton:global(.ant-btn.ant-btn-default) {
  border: none;
  box-shadow: none;
  background-color: var(--keyword-bgcolor);
  &:active, &:focus {
    border: 1px dashed var(--keyword-bgcolor);
  }
  &:hover {
    color: var(--ant-color-text-base);
  }
}

.keywordsOffButton:global(.ant-btn) {
  border: 1px dashed;
  border-color: var(--ant-color-border);
  box-shadow: none;
  background-color: transparent;
  &:hover, &:active {
    border: 1px dashed var(--keyword-bgcolor);
  }

  &:focus {
    border: 1px dashed var(--ant-color-border);
  }
}

span.exportButtonText {
  display: none;

  @media @display-md {
    display: inline-block;
  }
}

.spinnerIcon {
  margin-right: 0.5rem;
}

.tutorialAnimation {
  animation-delay: 0.5s;
  animation-name: grow;
  animation-duration: 2s;
  transition: ease-in-out;
}

.checkboxGroup:global(.ant-checkbox-group) {
  display: flex;
  flex-direction: column;
}

.toolbarWhole {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.columnsButton {
  font-size: var(--ant-font-size-sm);
  &:hover {
    color: var(--ant-color-primary);
  }
}

@keyframes grow {
  25% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";