.search-results {
  width: 100%;
  font-size: 14.5px;
  padding-left: 0;
  @media @display-lg {
    padding-left: 2rem;
  }
  .ant-row {
    align-items: flex-start;
  }
  &__gutter-row {
    padding-bottom: 5px;
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  &__results-wrapper {
    display: flex;
    align-items: flex-start;
    @media @display-md {
      align-items: center;
    }
  }
  &__results_speakers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media @display-lg {
      flex-direction: row;
      align-items: baseline;
      flex-wrap: wrap
    }
  }
  &__speaker-wrapper {
    height: 100%;
    display: flex;
    margin-right: 0.7rem;
    align-items: flex-start;
    @media @display-md {
      align-items: center;
    }
  }
  &__searched-item {
    display: flex;
    justify-content: flex-end;
    margin: 4px 0.5rem 0 0;
  }
  &__string {
    background-color: var(--ant-color-info-hover);
    color: var(--ant-layout-header-color);
  }
  &__result {
    display: flex;
    padding-left: 0;
    
    @media @display-sm {
      align-items: center;
      min-width: 180px;
      padding-left: 1rem;
    }
  }
  &__notes {
    height: 100%;
    display: flex;
    align-items: center;
    @media @display-sm { 
      justify-content: flex-start;
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";