.textArea:global(.ant-input):hover {
  border-color: var(--ant-color-primary-hover)
}

.summary {
  margin-top: 2rem;
}

.copyIcon {
  text-align: right;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";