.page {
  padding: 0;
  @media @display-md {
    padding: 1rem;
  }
}

.teamCredit {
  width: 100%;
  @media @display-md {
    width: 80%;
  }
  @media @display-xxl{
    width: 50%;
  }
  .space {
    width: 100%;
  }
}

.creditInfo {
  min-height: 350px;
  @media @display-xl {
    flex-direction: row;
  }
}

.title {
  font-size: var(--font-size-large);
  font-weight: 500; 
}


.details {
  font-weight: var(--ant-font-weight-strong);
}

.info {
  font-size: var(--font-size-tiny);
  :global(.anticon) {
    margin-right: 0.3rem;
  }
}

.subscription {
  margin-top: 2rem;
}

.noSubscription {
  width: fit-content;
  border: 1px dotted var(--ant-color-border);
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: var(--ant-font-size-sm);
  p {
    margin: 0
  }
}

.ranOutCredit {
  margin-top: 1.5rem;
}

.button {
  margin-top: 1rem;
  margin-left: 0.5rem;
  text-align: center;
  @media @display-sm {
    width: 50%;
  }
}

.card:global(.ant-card) {
  border: 1px solid var(--ant-color-border);
  margin-top: 1rem;
  :global(.ant-card-head) {
    border-bottom: 1px solid var(--ant-color-border);
  }
  :global(.ant-card-body) {
    margin-top: 1rem;
  }
}

.iconActive {
  color: var(--ant-color-success);
}

.iconCanceled {
  color: var(--ant-color-error);
}

.select {
  width: 7rem;
  @media @display-md {
    width: 5rem;
  }
}

.inputNumber {
  margin-top: 1rem;
  :global(.ant-input-number-input-wrap) {
    width: 5rem;
  }
}

.gridContainer {
  margin-top: 1rem;
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(2, 1fr); 
  gap: 0.7rem;
  @media @display-sm {
    width: 50%;
  }
  & :global(.ant-form-item-explain-error) {
    font-size: var(--font-size-tiny);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";