.iconButton {
  &:hover {
    color: var(--ant-color-primary-hover);
  }
  &Danger:hover, &Danger:focus{
    color:var(--ant-color-error);
  }
  &[disabled],  &Danger[disabled] {
    color: var(--ant-color-text-disabled);
    cursor: not-allowed;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";