.total {
  text-align: left;
}

.switch {
  margin-bottom: 1rem;
}

.allData {
  margin-right: 0.5rem;
  font-size: var(--font-size-tiny);
}

.userData {
  margin-left: 0.5rem;
  font-size: var(--font-size-tiny);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";