.billingInfoModal {
  label:global(:not(.ant-checkbox-wrapper)) {
    font-weight: var(--ant-font-weight-strong);
  }

  :global(.ant-form-item) {
    margin-bottom: 0.5rem;
  }
}

.hidden {
  display: none;
}

.ids {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;

  :global(.ant-form-item) {
    flex-grow: 1;
  }
}

.addressInputs {
  &Text {
    flex: 2 1 60%;
  }

  &Number {
    flex: 1 2 8rem;
  }
}

.divider:global(.ant-divider) {
  margin-bottom: 0.2rem;
  font-size: var(--ant-font-size);
}

.consents {
  margin-top: 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";