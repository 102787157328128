.paymentInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.iconActive {
  color: var(--ant-color-success);
}

.iconFailed {
  color: var(--ant-color-error);
}

.iconButton {
  padding: 0;
  color: var(--ant-color-primary);
  width: fit-content;
  margin: 0 0 0 auto;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";