.title {
  margin-bottom: 1rem;
  margin-left: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
}

.claimsList {
  display: flex;
  align-items: center;

  &:nth-child(2n) {
    background-color: var(--ant-color-fill-tertiary);
  }
}

.label {
  margin: 0.3rem 0;
  width: 70%;
}

.column {
  flex-basis: 50%;
  padding: 0.5rem 0;
  @media @display-md {
    padding: 1rem;
  }
}

.feature {
  width: 100%;
  margin-bottom: 0.5rem;
  :global(.ant-space.ant-space-horizontal) {
    width: auto;
  }
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.iconButton {
  padding: 0;
  color: var(--ant-color-primary);
  width: fit-content;
  margin: 0 0 0 auto;
}

.icon {
  font-size: 1.5rem;
  font-weight: 600;
}

.claimsTable {
  @media @display-sm {
    column-count: 2;
    column-gap: 1rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}

.featuresTitle {
  padding: 0 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";