.form {
  :global(.ant-form-item-label) {
    width: 40%;

    @media @display-md {
      width: 52%;
    }

    @media @display-xl {
      width: 40%;
    }

    text-align: left;
    padding: 0;
  }
}

.row {
  line-height: 1.8;
}

.btnSave {
  text-align: end;
  margin: 0.8rem 0 1.5rem;

  button {
    width: 8rem;
    margin-right: 0;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";