* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

:root {
  // Theme adaptive styles not controlled by Ant Design
  --header-color-hover: lighten(#ffae43, 6%);
  --header-button-border: 3px solid transparent; // button style dependent on header bg
  --controls-button-color: lighten(#ffdaa9, 9%); // project menu controls button
  --controls-text-color-hover: #444; // adapts to color with transparent controls
  --project-menu-bg: lighten(#ffae43, 35.5%); // editor project menu
  --form-item-color: #90d7ff; // fixes ANTD bug with checkbox color
  --border-style: none; // border style on cards, editor, menu
  --box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.2);
  --box-shadow-light: 0 1px 8px rgba(0, 0, 0, 0.056);
  --active-card-background: lighten(#ffae43, 15%);

  // Caption colors
  --caption-color-red: #f00;
  --caption-color-blue: #00f;
  --caption-color-lime: #0f0;
  --caption-color-yellow: #ff0;
  --caption-color-magenta: #f0f;
  --caption-color-cyan: #0ff;
  --caption-color-black: #000;
  --caption-color-white: #fff;

  // Caption warning colors
  --warning-faster-than-optimum: rgb(255, 255, 220);
  --warning-too-fast-critical: rgb(250, 242, 91);
  --warning-duration-short: rgb(250, 242, 91);
  --warning-duration-long: #ffbb62;
  --warning-too-long: #FFD5D6;
  --warning-unaligned: #c8dffa;

  // Section name warnings
  --warning-section-name-missing: #FFD5D6;

  // Highlighting of words in editor
  --keyword-bgcolor: #9cd71c;
  --keyword-text-black: #000000;
  --keyword-text-white: #FFFFFF;
  --shadow: #a1a1a1;
  --active-result-shadow: 0px 0px 4px 2px var(--shadow);
  --active-result-radius: 3px;
  --search-result: #AAFFFF;

  // Other
  --font-weight-base: 400;
  --font-weight-medium: 500;
  --font-size-large: 1.1rem;
  --font-size-tiny: 13px;
  --font-size-tags: 12px;
}

[data-theme="apa"] {
  // Theme adaptive styles not controlled by Ant Design
  --header-color-hover: #ffffff94;
  --controls-text-color-hover: #f06900;
  --header-button-border: 3px solid white;
  --project-menu-bg: #f6f6f6;
  --controls-button-color: transparent;
  --form-item-color: #f06900;
  --border-style: 1px solid #D9D9D9;
  --box-shadow: none;
}

html {
  overflow: hidden; // NOTE: Prevents additional toolbar for whole page.

  @media @display-sm {
    // TODO: Import Ant variables to non-Ant components
    font-size: 15px;
  }

  font-size: 14px;
}

body {
  background-color: var(--ant-layout-body-bg);
  color: var(--ant-color-text);
  min-height: 100dvh;
  background-attachment: fixed;
  word-break: break-word; // fixes bug - very long words expanded editor

  ::selection {
    background: var(--ant-color-primary);
    color: white;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: var(--ant-font-weight-strong);
}

h1 {
  font-size: var(--ant-font-size-heading-1);
}

h2 {
  font-size: var(--ant-font-size-heading-2);
}

p {
  line-height: 150%;
  color: var(--ant-color-text);
}

a {
  color: var(--ant-color-primary);
}

button {
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;

  &[disabled] {
    cursor: not-allowed;
  }
}

deepl-inline-translate {
  display: none;
}

deepl-inline-popup {
  display: none;
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;

  &-track {
    border-radius: 0.6rem;
    background-color: var(--ant-color-fill-tertiary);
  }

  &-thumb {
    background: var(--ant-color-border);
    border-radius: 0.6rem;

    &:hover,
    &:active {
      background-color: var(--ant-color-text-disabled);
    }
  }
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

// NOTE: AntD components overrides that do not work using ant-config.
.ant-tag {
  margin: 0.2rem;

  &.ant-tag-checkable {
    border: dashed 0.5px var(--ant-color-text-disabled);
  }
}

.ant-badge {
  cursor: pointer;
}

.ant-radio-wrapper {

  &:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    border-color: var(--form-item-color);
  }
}

.ant-checkbox-wrapper:hover {
  .ant-checkbox .ant-checkbox-inner {
    border-color: var(--form-item-color);
  }

  // NOTE: AntD bug: these properties are missing in the config so we have to override them. 
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--form-item-color) !important;
  }
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--form-item-color);
  border-color: var(--form-item-color);
}

.ant-layout.ant-layout-has-sider>.ant-layout-content {
  overflow-x: hidden;
}

.ant-layout-content {
  min-height: initial;
}

.ant-layout {
  min-height: 100dvh;
}

.ant-form-item-explain-error {
  font-size: var(--ant-font-size-sm);
}

.ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  @media @display-md {
    display: inline-block;
  }
}

textarea.ant-input {
  border-radius: var(--ant-border-radius);
}

.ant-alert-with-description .ant-alert-message {
  font-weight: var(--ant-font-weight-strong);
}

.anticon-question-circle svg,
.anticon-info-circle svg {
  fill: var(--ant-color-info);
}

.anticon-exclamation-circle {
  color: var(--ant-color-warning);
}

.ant-table-thead {
  tr {
    height: 2.6rem;
  }
}

.ant-collapse-header {
  font-weight: var(--ant-font-weight-strong);
}

.ant-card {
  margin-top: var(--ant-margin-xxs);
  border: var(--border-style);
}

.ant-color-picker .ant-color-picker-inner-content .ant-color-picker-presets-items {
  gap: 0.5rem;
}

.ant-menu-sub.ant-menu-inline {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: var(--project-menu-bg);
}

.ant-menu-sub.ant-menu-vertical {
  border-radius: 15px;
}

.ant-table-default {
  table-layout: auto;
}

.ant-table-row a {
  color: var(--ant-color-text-base);
  word-break: normal;

  &:hover {
    color: var(--ant-color-primary-hover);
  }
}

.ant-table-header .ant-table-cell:first-child {
  padding-left: 15px;
}

// NOTE: This is neccessary because it's used in the tutorial on the entire app
.react-joyride__tooltip {
  button[title="Close"] {
    display: none;
  }

  button[title="Last"] {
    display: none;
  }

  button[title="TutorialBack"] {
    font-size: var(--ant-font-size-sm);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";