.beeyTag {
  font-size: var(--font-size-tags);
  text-transform: none;
  width: fit-content;
  white-space: normal;
  text-align: left;
  align-self: center;

  & :global(.ant-spin) {
    position: absolute;
    left: 40%;
  }

  &:global(.ant-tag.ant-tag-checkable) {
    border: dashed 0.5px var(--ant-color-text-disabled);
  }

  &:global(.ant-tag.ant-tag-checkable.ant-tag-checkable-checked) {
    border: none;
  }
}

.beeyTagDisabled:global(.ant-tag-checkable) {
  opacity: 50%;
  cursor: not-allowed;

  &:hover {
    color: var(--ant-color-text-base);
  }
}

.shortTag {
  width: 40px;
  font-size: var(--font-size-tags);
  text-align: center;
  border: none;
}

.sectionTag {
  font-style: italic;
  border-radius: var(--ant-border-radius);
  margin-top: 0.2rem;
  font-size: var(--ant-font-size);
  white-space: nowrap;
  max-width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;

  @media @display-md {
    max-width: 8rem;
  }
}

.addNew {
  color: var(--ant-layout-header-color);
  background-color: var(--ant-color-info-hover);
}

.clippedTag {
  padding-right: 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";