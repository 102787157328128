.speakerColorPicker {
  &:global(.ant-color-picker-trigger) {
    border-radius: var(--ant-border-radius);
    padding: 1rem 0.5rem;
  }

  :global(.ant-color-picker-color-block) {
    border-radius: 50% !important;
    border: 1px solid var(--shadow);
  }
}

.circleButton {
  border: none;
  
  &:global(.ant-color-picker-trigger) {
    padding: 0;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";