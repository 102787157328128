.captionReviewModal {
  :global(.ant-form-item .ant-form-item-label > label) {
    display: inline;
  }
  :global(.ant-row.ant-form-item-row) {
    align-items: center;
  }
  :global(.ant-form-item) {
    margin-bottom: 0.5rem;
  }
  :global(.ant-modal-footer) {
    margin-top: 0;
  }
}

.divider {
  margin-top: 0.5rem;
}

.subHeader {
  font-weight: var(--font-weight-base);
  font-size: var(--ant-font-size);
}

.buttons {
  margin-top: 1rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";