.spin {
  text-align: center;
}

.buttonMore {
  width: 100%;
}

.providers {
  max-height: 20vh;
  overflow: auto;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";