.projectMenuSider:global(.ant-layout-sider) {
  background-color: var(--project-menu-bg);
  padding-bottom: 10px;
  outline: var(--border-style);
  overflow: auto;

  @media @display-lg {
    margin-left: 1rem;
  }

  :global(.ant-layout-sider-children) {
    height: 95%;
    justify-content: flex-start;

    :global(.ant-menu) {
      background-color: var(--project-menu-bg);
      padding-bottom: 1.5rem;
    }
  }

  :global(.ant-menu-title-content) {
    line-height: 30px;
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);

    .textArea {
      text-align: center;

      textarea {
        width: 90%;
        background-color: white;
      }
    }
  }

  :global(.ant-layout-sider-trigger) {
    height: 2rem;
    line-height: 2rem;
    z-index: 0;
    background-color: var(--project-menu-bg);
    text-align: right;

    :global(.anticon) {
      color: var(--ant-color-text-base);
      margin-right: 1rem;
      &:hover {
        color: var(--ant-color-primary-hover);
      }
    }
  }
}

:global(.ant-menu-vertical) {
  .submenu:global(.ant-menu-item) {
    height: auto;
    padding-inline: 0;

    .buttonDropdown {
      width: 100%;
      border-radius: 0;
      margin: 0;
      padding: 0.7rem 1.5rem;
      background-color: transparent;
      color: var(--text-color);

      &:hover {
        background-color: var(--ant-color-fill-tertiary);
      }
    }
  }
}

:global(.ant-menu-inline) {
  .submenu:global(.ant-menu-item) {
    margin: 0;
    margin-bottom: 0;
    padding-left: 0 !important;
    padding-inline: 0;
    height: auto;

    .controls-section {
      margin: 0;

      &__head {
        margin: 0;
      }
    }

    &:hover {
      color: var(--controls-text-color-hover);
      cursor: default;
    }
  }
}

.collapsed {
  :global(.ant-layout-sider-children) {
    padding: 0;

    :global(.ant-menu-inline-collapsed) {
      width: 100%;
      background-color: var(--project-menu-bg);
    }
  }
}

.translateIcon:hover>svg {
  stroke: var(--ant-color-primary-hover);
}

.drawer {
  :global(.ant-drawer-content) {
    background: var(--project-menu-bg);
  }

  :global(.ant-drawer-body) {
    padding: 0;
  }

  :global(.ant-drawer-header) {
    background-color: var(--project-menu-bg);
  }
}

.author {
  text-transform: none;
  margin-left: 5%;
  height: 1.5rem;
}

.authorName {
  margin-left: 0.5rem;
  color: var(--shadow);
}

.tags:global(.ant-menu-item) {
  padding: 5px 10px;
}

.notesTitle {
  margin-left: 5%;
  font-weight: var(--ant-font-weight-strong);
}

.notesContent {
  resize: none;
  width: 95%;
  cursor: default;
}

.projectMenuTags {
  padding: 0.5rem 1rem;
}

.projectMenuTagsSection {
  display: flex;
  flex-wrap: wrap;
  padding-inline: 1.5rem;
}

.editButton {
  text-align: right;
  margin-right: 5%;

  button {
    width: fit-content;
  }
}

.notes {
  text-transform: none;
  margin-left: 5%;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";