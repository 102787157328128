.tutorial {
  &__tip {
    margin-top: 1rem;
    .anticon {
      margin-left: 0.5rem;
    }
  }
  &__heading {
    margin-bottom: 0;
  }
  &__button {
    background-color: var(--ant-color-primary);
    border-radius: 30px;
    padding: 7px 30px;
    font-size: 16px;
    color: white;
    &:hover {
      color: white;
      background-color: var(--ant-color-primary-hover);
    }
  }
  &__tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__emoji {
    width: 5rem;
    margin-bottom: 1rem;
  }
  &__header {
    display: flex;
    justify-content: end;
    margin-bottom: 0.5rem;
    &-steps {
      display: flex;
      justify-content: space-around;
      gap: 3px;
    }
  }
  &__step {
    aspect-ratio: 1;
    background-color: var(--ant-color-border);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    &--active {
      background-color: var(--ant-color-primary);
    }
  }
  &__skip {
    color: var(--ant-color-text-secondary);
    margin-top: 0.5rem;
  }
  &__warning {
    padding: 5px;
    border-radius: 10px;
    font-weight: 300;
    margin-bottom: 5px;
    &--red {
      background-color: var(--warning-too-long);
    }
    &--light-yellow {
    background-color: var(--warning-faster-than-optimum);
    }
    &--yellow {
      background-color: var(--warning-too-fast-critical);
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";