.quillContainer {
  font-family: beey-editor;
  font-weight: 460;
  // NOTE: Fixes legacy issue with scrollbar.
  overflow-y: auto;

  :global(.ql-editor) {
    padding: 0 2rem 1.5rem 2rem;
    background-color: white;
    font-size: inherit;
    border-left: var(--border-style);
    border-right: var(--border-style);
    // NOTE: Following 3 lines fix legacy issues with scrollbar.
    height: auto;
    overflow-y: hidden;
    min-height: 100%;

    @media @display-md {
      padding: 0rem 3.5rem 2rem 3rem;
    }

    p {
      line-height: 1.7;
      color: var(--ant-color-text-secondary);
      margin: 0 0 0.4em 0;
    }

    h1, h2, h3, h4 {
      max-width: 85%;
      margin: 1em 0em 0.5em 0em;
      color: black;
    }

    h1 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 1.2rem;
      warning {
        display: inline-block;
        border-radius: var(--ant-border-radius);
        padding-left: 0.4rem;
      }
      uneditable {
        display: inline-block;
        padding-right: 0.3rem;
      }
    }

    h4 span, h4 search {
      display: inline-block;
      cursor: pointer;
      position: relative;
      padding: 0.2rem 0.3rem;
      // NOTE: Keeps text aligned with paragraph even with background padding.
      right: 0.3rem;
      border-radius: var(--ant-border-radius);
      &:hover {
        background-color: var(--ant-color-fill-tertiary);
      }
    }

    span {
      color: var(--ant-color-text-secondary);
    }

    search {
      display: inline;
    }

    :global(.unaligned) {
      background-color: var(--warning-unaligned);
    }

    :global(.timeStampMarker) {
      font-size: var(--ant-font-size-sm);
      opacity: 0.8;
      cursor: default;
    }

    :global(.summary) {
      pointer-events: none;
      background-color: var(--ant-color-bg-container-disabled);
      position: relative;
      margin: 0px;
    }

    :global(.summary-nobutton) {
      padding: 0.7rem 0.7rem 0 0.7rem;
    }

    :global(.summary-button) {
      padding: 0.7rem 0.7rem 2.5rem 0.7rem;
      &::after {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        pointer-events: auto;
        content: attr(data-button);
        cursor: pointer;
        color: var(--ant-color-text-base);
        font-size: var(--ant-font-size);
        border: 1px solid var(--ant-color-border);
        border-radius: var(--ant-border-radius);
        padding: 0.1rem 0.5rem;
        position: absolute;
      }
    }

    &:global(.show-summaries) {
      // NOTE: Targets the last paragraphs that follows .section.
      .section ~ p:last-of-type {
        padding-bottom: 1.4em;
        border-bottom: 1px solid var(--ant-color-border);
      }
      // NOTE: First section does not have a preceding border.
      .section:not(:first-child) {
        padding-top: 1rem;
        border-top: 1px solid var(--ant-color-border);
      }
    }

    :global(.sections-select-enabled) h2 uneditable {
      cursor: pointer;
    }

    :global(.warning-faster-than-optimum) {
      background-color: var(--warning-faster-than-optimum);
    }

    :global(.warning-too-fast-critical) {
      background-color: var(--warning-too-fast-critical);
    }

    :global(.warning-duration-short) {
      background-color: var(--warning-duration-short);
    }

    :global(.warning-duration-long) {
      background-color: var(--warning-duration-long);
    }

    :global(.warning-too-long) {
      background-color: var(--warning-too-long);
    }

    :global(.warning-section-name-missing) {
      background-color: var(--warning-section-name-missing);
    }

    :global(.keyword-highlighted) {
      box-shadow: var(--active-result-shadow);
      border-radius: var(--active-result-radius);
      border: 1px solid var(--color-text-disabled);
    }

    :global(.keyword-text-color-black) {
      color: var(--keyword-text-black) !important;
    }

    :global(.keyword-text-color-white) {
      color: var(--keyword-text-white) !important;
    }

    :global(.search-result) {
      background-color: var(--search-result);
    }
    :global(.search-highlighted) {
      background-color: var(--search-result);
      box-shadow: var(--active-result-shadow);
      border-radius: var(--active-result-radius);
      border: 1px solid var(--color-text-disabled);
    }

    :global(.keyword-result) {
      box-shadow: none;
      border-radius: 0px;
      :global(.search-result) {
        background-color: transparent;
      }
    }
  }
}

.visibleNbspace {
  font-family: nbspace, beey-editor;
}

.readOnly {
  h4 span:hover, h4 search:hover, h2 uneditable {
    background-color: transparent;
    cursor: default;
  }
  :global(.summary-button::after) {
    cursor: not-allowed;
  }
}

.readOnlyTag {
  position: absolute;
  z-index: 10;
  right: 1.5rem;
  top: 0.5rem;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";