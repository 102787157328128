.playButton {
  color: var(--ant-color-text);
  margin-right: 0.5rem;
}

.playerTime {
  display: none;
  @media @display-sm {
    font-size: var(--ant-font-size-sm);
    display: block;
  }
}

.slider {
  width: 100%;
  margin: 0 0.25rem;
  @media @display-sm {
    width: 50%;
  }
}

.knobHidden {
  cursor: default;
  :global(.ant-slider-handle) {
    display: none;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";