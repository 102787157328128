.creditValidity {
  width: 100%;
  margin-left: 1rem;
  :global(.ant-collapse) {
    border: none;
  }
  :global(.ant-collapse-header) {
    font-weight: 500;
    // NOTE: Impossible to overwrite Ant class without !important
    padding: 0 !important;
    color: var(--ant-color-text-secondary) !important;
  }
  :global(.ant-collapse-content) {
    border-top: none;
  }
  :global(.ant-collapse-item) {
    border-bottom: none;
  }
}

.label {
  font-size: var(--font-size-large);
  font-weight: 400; 
}

.credit {
  font-size: var(--font-size-large);
}

.remainingCredit {
  margin: 2rem;
}

.space {
  width: 100%;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";