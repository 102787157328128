.statusbar {
  display: flex;
  align-items: center;
  line-height: 15px; 
  margin-left: 0.5rem;

  .ant-progress-inner .ant-progress-circle-path, .ant-progress-text {
    stroke: var(--ant-color-info);
    color: var(--bg-adaptive-text-color);
  }

  .anticon-spin svg {
    color: var(--ant-color-info);
  }

  &__box {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    margin-right: 0.5rem;
    font-weight: var(--ant-font-weight-strong);
    white-space: nowrap;
  }

  &__text {
    display: none;
    @media @display-lg {
      display: block;
    }
  }

  .anticon-close-circle svg {
    fill: var(--bg-adaptive-text-color);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";