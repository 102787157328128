.preview {
  position: relative;
  margin-top: 0.5rem;
}

.image {
  width: 100%;
  border-radius: var(--ant-border-radius);
}

.text {
  position: absolute;
  width: 100%;
}

.previewLabel {
  :global(.ant-row.ant-form-item-row) {
    align-items: flex-start;
  }
}

.line {
  white-space: nowrap;
}

.text {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .black span {
    background-color: rgba(0, 0, 0, 1);
  }
  .semi span {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .transparent span {
    background-color: transparent;
  }
  span {
    padding: 0.2rem;
    display: inline-flex;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";