.formInput {
  margin-bottom: 0.7rem;
  @media @display-sm {
    margin-bottom: 0.7rem;
  }
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.button {
  width: 100%;
}

.loginLink {
  display: flex;
  justify-content: center;
  button {
    background-color: transparent;
    box-shadow: none;
    border: none;
    margin: 0;
    &:hover {
      background-color: transparent;
    }
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";