.addButton {
  padding: 0 0.7rem;
  height: 2.2rem;
  font-weight: var(--ant-font-weight-strong);
  display: flex;
  align-items: center;
}

.addIcon {
  font-size: var(--ant-font-size-lg);
}

.title {
  letter-spacing: 1px;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";