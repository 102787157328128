.addEditModal {
  :global(.ant-form-item) {
    margin-bottom: 24px;
  }
}

.saveButton {
  display: block;
  margin: 1rem 0 0 auto;

  @media @display-sm {
    margin-top: 0;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";