.tooltip {
  position: fixed;
  z-index: 1000;
  border-radius: 15px;
  background-color: var(--ant-color-text-secondary);
  color: white;
  padding: 2px 10px 3px;
  min-height: 28px;
  text-align: center;
  transform:translate(-100%, calc(-100% - 6px));
  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: calc(100% - 20px);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--ant-color-text-secondary) transparent transparent transparent;
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";