.helpSectionItem {
  height: 40px;
  padding: 0 16px;
  &:hover {
    background-color: var(--ant-color-fill-tertiary);
  }
  button {
    padding: 0;
  }
}

.noClick {
  pointer-events: none;
  font-style: italic;
  border-top: 1px solid var(--border-gray);
  background-color: var(--ant-color-fill-tertiary);
  span {
    margin-left: 0.2rem;
  }
}

.helpButton {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dropdown :global(.ant-dropdown-menu) {
  padding: 0;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";