.keywordsResults {
  width: 100%;
  font-size: 14.5px;
  padding-left: 0;
  @media @display-lg {
    padding-left: 2rem;
  }
  &:global(.ant-row) {
    align-items: flex-start;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.gutterRow {
  padding-bottom: 5px;
}

.resultsWrapper {
  display: flex;
  flex-wrap: wrap;
  @media @display-md {
    align-items: center;
  }
}

.keywordText {
  margin-right: 5px;
}

.searchedItem {
  margin-right: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.keywordResult {
  padding: 0 0.3rem;
  background-color: var(--keyword-bgcolor);
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";