.label {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  transition: 600ms;

  :global(.anticon) {
    color: var(--ant-color-info);
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }
}
  
.control {
  flex: 1;
  text-align: left;
  visibility: visible;
  opacity: 1;  
  transition: 600ms;
}

.labelDisabled {
  color: var(--ant-color-text-disabled);
  transition: 600ms;
}

.controlDisabled {
  visibility: hidden;
  opacity: 0;
  transition: 600ms;
}


@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";