.submitButton {
  order: 1
}

.speakerForm {
  :global(.ant-modal-body) {
    padding: 0;
  }

  :global(.ant-radio-wrapper) {
    margin: 0.3rem 0;
  }
}

.controls :global(.ant-form-item) {
  margin-bottom: 0;
}

.colorBox {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: var(--ant-line-width) solid var(--shadow);
  border-radius: var(--ant-border-radius-sm);
}

.defaultColorText {
  margin: 0 0.5rem 0 0.5rem;
}

.defaultColorWrapper,
.defaultColorText {
  display: inline;
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";