.logoutButton {
  padding: 1rem 0;
  width: 100%;
  text-align: left;
}

.divider {
  margin: 0;
  &Bottom {
    margin-bottom: 0.8rem;
  }
  &Top {
    margin-top: 0.8rem;
  }
}

.logos {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media @display-sm {
    gap: 1rem;
  }
}

.msp {
  flex-direction: column;
  gap: 0.1rem;
}

.productInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.beeyInfo {
  font-size: small;
  text-align: end;
}

.user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 1rem;
  &Detail {
    margin-bottom: 0;
  }
  :global(.anticon) {
    background-color: var(--ant-color-bg-container-disabled);
    padding: 0.5rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }
}

.link {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  a {
    color: var(--ant-color-text-base);
    width: 100%;
    &:hover {
      color: var(--ant-color-text-base);
    }
  }
  &:hover {
    background-color: var(--ant-color-fill-tertiary);
    cursor: pointer;
  }
}

.language {
  display: flex;
  align-items: center;
  margin: 0 1rem;
   .label {
    flex-grow: 1;
  }

  .langControls {
    flex-grow: 2;
    :global(.ant-select) {
      width: 100%;
    }
    :global(.ant-select-show-search .ant-select-selector) {
      cursor: pointer;
    }
  }
}

.icon:global(.anticon) {
  margin-right: 0.5rem;
  svg {
    fill: var(--ant-color-text-base);
  }
}

@display-sm: ~"only screen and (min-width:576px)";@display-md: ~"only screen and (min-width:768px)";@display-lg: ~"only screen and (min-width:992px)";@display-xl: ~"only screen and (min-width:1200px)";@display-xxl: ~"only screen and (min-width:1680px)";